import { forwardRef, useState } from 'react';
import {
	Col,
	Container,
	Row,
} from 'reactstrap';
import * as Scroll from 'react-scroll';

import { FormClient } from './form/Client';
import { create } from '../services/demande';
import { FormReclamation } from './form/Reclamation';
import { Success } from './form/success';

const scroll = Scroll.animateScroll;

const getDefaultFormData = () => ({
	vousEtes: '',
	isClient: true,
	titulaire: false,
	files: [],
});

const getDebugDefaultFormData = () => ({
	civilite: 'M',
	demande: `test : ${new Date()}`,
	//demandeType: 'versementFonds',
	dateNaissance: '',
	email: 'francois.michaudon@53js.fr',
	files: [],
	isClient: true,
	nom: 'Michaudon',
	numDossier: '1516qsd1',
	prenom: 'François',
	pricingConditionsAccepted: null,
	tel: '0641831907',
	//theme: 'faqAssuranceConsulterModifier',
	titulaire: false,
	verifEmail: 'francois.michaudon@53js.fr',
	vousEtes: '',
});

export const HomeForm = forwardRef((_, ref) => {
	const [error, setError] = useState(null);
	const [success, setSucces] = useState(false);
	const [secondTabOpen, setSecondTabOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState(
		process.env.NODE_ENV !== 'production' && process.env.REACT_APP_DEBUG
			? getDebugDefaultFormData()
			: getDefaultFormData(),
	);

	const handleChange = (newData) => {
		setFormData(newData);
		setSucces(false);
		setError(null);
	};

	const handleChangeSpecific = (newValues) => {
		setFormData((p) => ({ ...p, ...newValues }));
		setSucces(false);
		setError(null);
	};

	const openTab = (val) => {
		setSecondTabOpen(val);
	};

	const handleSubmit = () => {
		if (secondTabOpen === false) {
			openTab(true);
			scroll.scrollMore(200, { duration: 500 });
		} else {
			setLoading(true);
			create(formData)
				.then((response) => {
					if (response.ok) {
						return response.json();
					}
					const err = new Error(`${response.status} ${response.statusText}`);
					err.status = response.status;
					throw err;
				})
				.then((json) => {
					if (json.success) {
						setFormData(getDefaultFormData());
						setLoading(false);
						setSucces(true);
						setSecondTabOpen(false);
						setError(null);
					} else {
						throw new Error(json);
					}
				})
				.catch((e) => {
					// eslint-disable-next-line no-console
					console.log(e);
					setLoading(false);
					setError(e);
				});
		}
	};

	return (
		<Container tag="main">
			<div ref={ref}>
				<FormReclamation
					formData={formData}
					onChange={handleChange}
					onChangeSpecific={handleChangeSpecific}
					// onThemeChange={handleThemeChange}
					onEdit={openTab}
					onSubmit={handleSubmit}
					secondTabOpen={secondTabOpen}
				/>
			</div>
			<FormClient
				error={error}
				formData={formData}
				loading={loading}
				onChange={handleChange}
				onChangeSpecific={handleChangeSpecific}
				onSubmit={handleSubmit}
				open={secondTabOpen}
				success={success}
			/>
			<Row>
				<Col md={{ size: 7, offset: 3 }} lg={{ size: 6, offset: 4 }} className="text-left">
					{ success ? <Success /> : null }
				</Col>
			</Row>
		</Container>
	);
});
