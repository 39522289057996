import Helmet from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';

import { Footer } from '../components/Footer';
import { HeadSimple } from '../components/HeadSimple';

export const Charte = () => (
	<div>
		<Helmet>
			<title>macaissedepargnecotedazurmerepond.fr – Charte d&apos;utilisation</title>
		</Helmet>
		<HeadSimple className="head-charte">
			<header className="header">
				<div className="header-title">
					<Container>
						<Row>
							<Col>
								<strong className="xl-title">CHARTE D&apos;UTILISATION</strong>
							</Col>
						</Row>
						<Row className="bottom-text">
							<Col>
								<h1 className="md-title">
									Caisse d&apos;Epargne Côte d&apos;Azur
									<strong>Ma Caisse d&apos;Epargne Côte d&apos;Azur me répond</strong>
								</h1>
							</Col>
						</Row>
					</Container>
				</div>
			</header>
		</HeadSimple>
		<Container tag="main" className="mentions-content py-4">
			<Row tag="article">
				<Col>
					<p>
						La Caisse d&apos;Epargne Côte d&apos;Azur
						a créé ce présent site internet afin de répondre
						à vos interrogations et de vous exprimer en postant vos demandes et le cas échéant,
						vos réclamations, sur les produits et services bancaires de votre banque
						Caisse d&apos;Epargne Côte d&apos;Azur.
					</p>
					<p>
						Ce présent site internet n&apos;est pas
						un site dédié à la gestion des comptes bancaires.
					</p>
					<p>
						Ce présent site internet n&apos;est pas un dispositif de traitement d&apos;opérations
						et d&apos;échanges de données bancaires sensibles. En cas de problèmes liés au
						non-respect des conditions d&apos;utilisation,
						la Caisse d&apos;Epargne Côte d&apos;Azur serait dégagée de toute responsabilité.
					</p>
					<p>
						Vous trouverez des réponses à vos questions relatives à l&apos;utilisation
						du site grâce à la page suivante :
					</p>
					<ul>
						<li>
							Déposez votre demande en ligne.
						</li>
					</ul>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>VOUS SOUHAITEZ EXPRIMER UNE DEMANDE À LA CAISSE D&apos;EPARGNE CÔTE D&apos;AZUR ?</h2>
					<ol>
						<li>
							Sur la page d&apos;accueil, cliquez sur le bouton
							« Déposez votre demande en ligne ».
						</li>
						<li>Vous arrivez sur la page du formulaire, « JE SAISIS MA DEMANDE ».
							<ol>
								<li>
									Choisissez dans la liste déroulante,
									si vous êtes un client particulier ou professionnel.
								</li>
								<li>Sélectionnez le thème de votre demande.</li>
								<li>Sélectionnez le motif de votre demande.</li>
								<li>
									Sélectionnez des informations supplémentaires
									à votre demande si nécessaire.
								</li>
								<li>Saisissez votre message/question en français (2 500 caractères maximum).</li>
								<li>
									Vous avez également la possibilité de rajouter jusqu&apos;à 5 pièces jointes.
									Les extensions acceptées sont les suivantes .doc / .docx / .pdf / .jpg / .png.
									L&apos;ensemble des fichiers déposés doit peser moins de 10 MO.
									Les fichiers ne doivent pas comporter de données sensibles.
									En cas de problèmes liés au non-respect des conditions d&apos;utilisation,
									la caisse d&apos;Epargne Côte d&apos;Azur serait dégagée de toute responsabilité.
								</li>
								<li>Cliquez sur « Valider ».</li>
							</ol>
						</li>
						<li>
							Afin d&apos;envoyer votre formulaire, indiquez vos coordonnées en remplissant tous
							les champs demandés sur la seconde page « J&apos;INDIQUE MES COORDONNÉES ».
							<ol>
								<li>
									Client ou prospect – civilité –
									Nom – prénom – adresse mail – ville – pays – date de naissance.
								</li>
								<li>Cliquez sur « Envoyer » pour valider.</li>
							</ol>
						</li>
					</ol>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>LIENS UTILES</h2>
					<ol>
						<li>
							Lorsque vous cliquez sur le logo Caisse
							d&apos;Epargne Côte d&apos;Azur en haut à gauche, vous
							serez redirigé vers la « page d&apos;accueil ».
						</li>
						<li>
							Lorsque vous Cliquez sur NOTRE SITE : CAISSE D&apos;EPARGNE CÔTE D&apos;AZUR en bas
							à droite vous serait redirigé vers le
							site d&apos;information de la Caisse d&apos;Epargne
							Côte d&apos;Azur vous permettant d&apos;accéder à vos comptes.
						</li>
						<li>
							Le logo Facebook en bas à droite, représente un lien direct vers la page
							Facebook de la Caisse d&apos;Epargne Côte d&apos;Azur.
						</li>
						<li>
							Le logo Twitter en bas à droite, représente un lien direct vers la page Twitter
							de la Caisse d&apos;Epargne Côte d&apos;Azur.
						</li>
						<li>
							Les Mentions légales et crédit se trouvent en pied de page. Cliquez dessus pour
							ouvrir le contenu.
						</li>
					</ol>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>CHARTE DE MODÉRATION CAISSE D&apos;EPARGNE CÔTE D&apos;AZUR</h2>
					<p>
						Ce présent site internet a pour but de répondre à vos demandes sur les produits et
						services proposés par la Caisse d&apos;Epargne Cote d&apos;Azur.
					</p>
					<p>
						Pour faciliter les échanges dans le respect de chaque Utilisateur, seront exclus
						des échanges, les messages contraires à la loi du 29 juillet 1881 sur la liberté de
						la presse et, de façon non exhaustive :
					</p>
					<ul>
						<li>les contenus ne relevant pas de demandes ou réclamations clients/non clients.</li>
						<li>
							les contenus illégaux, contenant des propos injurieux, obscènes, vulgaires,
							diffamants, menaçants, racistes ou antisémites, ou faisant l&apos;apologie de
							crimes contre l&apos;humanité ou incitant à la haine, la violence à l&apos;égard de
							personnes (physiques ou morales), et à toutes atteintes à la digité humaine,
							ou se rapportant à la pornographie ;
						</li>
						<li>
							les propos impolis, agressifs, insultants, les affirmations graves
							non-prouvées ou notoirement inexactes concernant les personnes ou les
							organisations, l&apos;utilisation
							abusive ou malhonnête de l&apos;espace de discussion.
						</li>
						<li>les attaques personnelles, pris individuellement ou collectivement.</li>
						<li>
							les liens hypertextes dont la destination pourrait engager la responsabilité
							ou l&apos;image de la Caisse d&apos;Epargne Côte d&apos;Azur et/ou de ses partenaires.
						</li>
						<li>
							les messages rédigés en langue étrangère ou comportant un excès de signes ou
							onomatopées gênant la compréhension.
						</li>
					</ul>
					<p>
						Nous vous informons qu&apos;afin de bien administrer ce site,
						l&apos;équipe de modération se réserve le droit de retirer,
						à posteriori tout contenu soumis ne respectant pas les conditions
						susmentionnées et/ou jugé non pertinent avec cette page.
						<br />
						Les décisions de modération ne sont pas motivées ou notifiées.
					</p>
				</Col>
			</Row>
		</Container>
		<Footer />
	</div>
);
