import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const FormTitle = ({ title, num, className }) => (
	<h1 className={classnames('form-title text-danger mb-4', className)}>
		<span className="red-circle mb-2 mb-md-0 mr-md-2">{num}</span>
		<p className="m-0">{title}</p>
	</h1>
);

FormTitle.propTypes = {
	title: PropTypes.string.isRequired,
	className: PropTypes.string,
	num: PropTypes.number.isRequired,
};

FormTitle.defaultProps = {
	className: '',
};

export default FormTitle;
