import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import {
	Button,
	Col,
	FormGroup,
	Input,
	Label,
	Row,
} from 'reactstrap';
import Form, { Field, FieldError } from 'react-jsonschema-form-validation';

import Error from './error';
import FormTitle from './form-title';
import clientSchema from '../../schemas/client/client.schema';
import { Mentions } from './mentions/Mentions';
import Spinner from '../spinner';
import SwitchComponent from './switch';
import SimpleSelect from '../SimpleSelect';
import errorMessages from '../../lib/errorMessages';

export const FormClient = ({
	open, onChange, onSubmit, formData, loading, error, onChangeSpecific,
}) => {
	if (!open) return null;
	return (
		<section className="demand-form content my-4">
			<>
				<FormTitle title="J'INDIQUE MES COORDONNÉES" num={2} className="pt-4 border-top border-grey" />
				<Form
					data={formData}
					id="form-coordonnees"
					errorMessages={errorMessages}
					onChange={onChange}
					onSubmit={onSubmit}
					schema={clientSchema}
				>
					<FormGroup className="row align-items-center">
						<Label className="col-md-3 col-lg-4" htmlFor="client-isClient">Client Caisse d&apos;Epargne Côte d&apos;Azur* :</Label>
						<Col md="7" lg="6">
							<Field
								component={SwitchComponent}
								id="client-isClient"
								onChange={onChangeSpecific}
								name="isClient"
								value={formData.isClient}
							/>
							<FieldError name="isClient" />
						</Col>
					</FormGroup>
					<FormGroup className="row align-items-center">
						<Label className="col-md-3 col-lg-4" htmlFor="client-civilite">Civilité* :</Label>
						<Col md="7" lg="6">
							<Field
								component={SimpleSelect}
								boldTheme={false}
								id="client-civilite"
								name="civilite"
								onChange={({ value }) => onChangeSpecific({ civilite: value })}
								placeholder="Civilité"
								options={[
									{ value: 'M', label: 'M' },
									{ value: 'Mme', label: 'Mme' },
									{ value: 'Mlle', label: 'Mlle' },
								]}
								value={formData.civilite}
							/>
							<FieldError name="civilite" />
						</Col>
					</FormGroup>
					<FormGroup className="row align-items-center">
						<Label className="col-md-3 col-lg-4" htmlFor="client-nom">Nom* :</Label>
						<Col md="7" lg="6">
							<Field
								component={Input}
								id="client-nom"
								name="nom"
								placeholder="Nom"
								required
								type="text"
								value={formData.nom}
							/>
							<FieldError name="nom" />
						</Col>
					</FormGroup>
					<FormGroup className="row align-items-center">
						<Label className="col-md-3 col-lg-4" htmlFor="client-prenom">Prénom* :</Label>
						<Col md="7" lg="6">
							<Field
								component={Input}
								id="client-prenom"
								name="prenom"
								placeholder="Prénom"
								required
								type="text"
								value={formData.prenom}
							/>
							<FieldError name="prenom" />
						</Col>
					</FormGroup>
					<FormGroup className="row align-items-center">
						<Label className="col-md-3 col-lg-4" htmlFor="client-email">Adresse e-mail* :</Label>
						<Col md="7" lg="6">
							<Field
								component={Input}
								id="client-email"
								name="email"
								placeholder="Adresse e-mail"
								required
								type="text"
								value={formData.email}
							/>
							<FieldError name="email" />
						</Col>
					</FormGroup>
					<FormGroup className="row align-items-center">
						<Label className="col-md-3 col-lg-4" htmlFor="client-verifEmail">Vérification de l&apos;adresse e-mail* :</Label>
						<Col md="7" lg="6">
							<Field
								component={Input}
								id="client-verifEmail"
								name="verifEmail"
								placeholder="Adresse e-mail"
								onPaste={(e) => {
									e.preventDefault();
									return false;
								}}
								required
								type="text"
								value={formData.verifEmail}
							/>
							<FieldError
								errorMessages={{
									const: () => 'Les adresses e-mails ne sont pas identiques',
								}}
								name="verifEmail"
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row align-items-center">
						<Label className="col-md-3 col-lg-4" htmlFor="client-tel">Numéro de téléphone* :</Label>
						<Col md="7" lg="6">
							<Field
								component={Input}
								id="client-tel"
								name="tel"
								placeholder="Numéro de téléphone"
								required
								type="text"
								value={formData.tel}
							/>
							<FieldError name="tel" />
						</Col>
					</FormGroup>
					<FormGroup className="row align-items-center">
						<Label className="col-md-3 col-lg-4" htmlFor="client-dateNaissance">Date de naissance* :</Label>
						<Col md="7" lg="6">
							<Field
								className="form-control"
								component={InputMask}
								mask="99/99/9999"
								id="client-dateNaissance"
								name="dateNaissance"
								placeholder="Date de naissance (jj/mm/aaaa)"
								required
								type="text"
								value={formData.dateNaissance}
							/>

							<FieldError
								errorMessages={{
									pattern: () => 'La date doit être au format jj/mm/aaaa.',
								}}
								name="dateNaissance"
							/>
						</Col>
					</FormGroup>
					<Row className="mb-4">
						<Col md={{ size: 7, offset: 3 }} lg={{ size: 6, offset: 4 }} className="text-left">
							<Button
								disabled={loading}
								type="submit"
								name="submit-button"
								size="xl"
								color="danger"
							>
								<Spinner loading={loading}>ENVOYER</Spinner>
							</Button>
						</Col>
					</Row>
					<Row>
						<Col md={{ size: 7, offset: 3 }} lg={{ size: 6, offset: 4 }} className="text-left">
							{ error ? <Error err={error} /> : null }
						</Col>
					</Row>
					<Row>
						<Col md={{ size: 6, offset: 4 }}>
							<Mentions isClient={formData.isClient} />
						</Col>
					</Row>
				</Form>
			</>
		</section>
	);
};

FormClient.propTypes = {
	open: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.shape({}),
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onChangeSpecific: PropTypes.func.isRequired,
	formData: PropTypes.shape({
		dateNaissance: PropTypes.string,
		isClient: PropTypes.bool.isRequired,
		titulaire: PropTypes.bool.isRequired,
		files: PropTypes.arrayOf(PropTypes.shape({})),
		civilite: PropTypes.string,
		nom: PropTypes.string,
		prenom: PropTypes.string,
		email: PropTypes.string,
		verifEmail: PropTypes.string,
		tel: PropTypes.string,
	}).isRequired,
};

FormClient.defaultProps = {
	error: null,
};
