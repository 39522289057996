import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';
import Image from '../../Image';

import email1 from '../../../images/email1.png';
import email2 from '../../../images/email2.png';
import email3 from '../../../images/email3.png';
import email1p from '../../../images/email1.webp';
import email2p from '../../../images/email2.webp';
import email3p from '../../../images/email3.webp';

import './Faq.scss';

export const FormFaqModificationEmail = () => (
	<Row className="faq FormFaqModificationEmail">
		<Col xs="12">
			<h2>Comment modifier votre Email en ligne</h2>
			<p>
				Afin de modifier votre Email en ligne, je vous invite à réaliser
				<strong>les étapes suivantes</strong> :
			</p>
			<ol className="ol-steps">
				<li>
					<Image className="noShadow" src={email1} webp={email1p} alt="Etape 1 - Cliquez sur Plus puis sur Profil et données personnelles" />
					<p>
						Cliquez sur « Plus » puis<br /> sur « Profil et données personnelles »
					</p>
				</li>
				<li>
					<Image className="noShadow" src={email2} webp={email2p} alt="Etape 2 - Cliquez sur Email puis sur Entrez votre nouvel Email" />
					<p>
						Cliquez sur « Email » puis<br /> sur « Entrez votre nouvel Email »
					</p>
				</li>
				<li>
					<Image className="noShadow" src={email3} webp={email3p} alt="Etape 3 - Validez la modification avec Sécur'Pass" />
					<p>
						Validez la modification<br /> avec Sécur&apos;Pass
					</p>
				</li>
			</ol>

		</Col>
	</Row>
);
