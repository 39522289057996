import Helmet from 'react-helmet';
import { useRef } from 'react';

import { Footer } from '../components/Footer';
import Header from '../components/header';
import NavBar from '../components/nav-bar';
import { TitleBanner } from '../components/title-banner';
import { HomeForm } from '../components/HomeForm';

import './Accueil.scss';

export const Accueil = () => {
	const formRef = useRef(null);
	const handleClickTitleBanner = () => {
		formRef.current.scrollIntoView({ behavior: 'smooth' });
	};
	return (
		<div className="Accueil">
			<Helmet>
				<title>macaissedepargnecotedazurmerepond.fr - Votre Espace d&apos;Expression</title>
				<meta name="description" content="La Caisse d'Epargne Côte d'Azur vous propose de déposer vos demandes directement en ligne" />
			</Helmet>
			<NavBar />
			<Header />
			<TitleBanner onClick={handleClickTitleBanner} />
			<HomeForm ref={formRef} />
			<Footer />
		</div>
	);
};
