import PropTypes from 'prop-types';

import { MentionsClient } from './Client';
import { MentionsProspects } from './Prospects';

import './Mentions.scss';

export const Mentions = ({ isClient }) => (
	<section className="Mentions">
		{ isClient ?	<MentionsClient /> : <MentionsProspects /> }
	</section>
);

Mentions.propTypes = {
	isClient: PropTypes.bool.isRequired,
};
