import PropTypes from 'prop-types';
import {
	Col,
	FormGroup,
	Input,
	Label,
} from 'reactstrap';
import { Field, FieldError } from 'react-jsonschema-form-validation';

import { CustomFile } from './CustomFile';
import demandeTypeForm from './optional-fields';
import { demandeTypes, themes } from '../../schemas/data/data';
import SelectMotif from '../SelectMotif';

export const MotifFields = ({ formData, onChange, onEdit }) => {
	const handleChange = (valueLabel) => {
		const value = valueLabel ? valueLabel.value : '';
		onChange({
			demandeType: value,
		});
		onEdit(false);
	};

	const filteredDemandeTypes = formData.theme
		? themes[formData.theme].demandeTypes
		: Object.keys(demandeTypes);

	const DemandeTypeFormComponent = formData.demandeType && demandeTypeForm[formData.demandeType];
	const rechercheDocs = formData.theme === 'rechercheDocs';

	let placeholderContent = 'Merci de saisir votre texte et DE NE PAS SAISIR vos coordonnées bancaires, N° de compte ou identifiant/ Mot de passe.';
	if (formData.theme // theme séléctionné
		&& demandeTypes[formData.theme] // theme existant
		&& demandeTypes[formData.theme].customPlaceholder) { // theme possede un custom placeholder
		placeholderContent += `\n${demandeTypes[formData.theme].customPlaceholder}`;
	}

	return (
		<>
			{
				(!formData.demandeType // motif non séléctionné
					|| !demandeTypes[formData.demandeType] // ou précaution
					|| !demandeTypes[formData.demandeType].customMotif) && (
					//ou custom motif (exemple : autres)
					<FormGroup className="row">
						<Label className="strongs col-md-3 col-lg-4" htmlFor="reclamation-demandeType">Motif* :</Label>
						<Col md="7" lg="6">
							<Field
								component={SelectMotif}
								id="reclamation-demandeType"
								name="demandeType"
								disabled={!formData.theme}
								onChange={handleChange}
								options={filteredDemandeTypes}
								value={formData.demandeType}
							/>
							<FieldError name="demandeType" />
						</Col>
					</FormGroup>
				)
			}
			{
				(DemandeTypeFormComponent && (
					<DemandeTypeFormComponent formData={formData} onChange={onChange} />
				)) || null
			}

			<FormGroup className="row">
				<Label htmlFor="reclamation-demande" className="col-md-3 col-lg-4">Saisissez votre demande* :</Label>
				<Col md="7" lg="6">
					<Field
						component={Input}
						id="reclamation-demande"
						name="demande"
						placeholder={placeholderContent}
						required
						type="textarea"
						rows="7"
						value={formData.demande}
					/>
					<FieldError name="demande" />
				</Col>
			</FormGroup>
			<CustomFile onChange={onChange} files={formData.files} />
			{
				rechercheDocs && (
					<FormGroup className="row">
						<Col md={{ size: 7, offset: 3 }} lg={{ size: 6, offset: 4 }}>
							<div className="form-check">
								<Label htmlFor="pricing-condtions" className="form-check-label">
									<Field
										component={Input}
										id="pricing-condtions"
										name="pricingConditionsAccepted"
										required
										type="checkbox"
										className="tarification-checkbox"
										value={formData.pricingConditionsAccepted}
									/>
									J&apos;ai bien noté que cette prestation pourra être soumise à tarification selon les {' '}
									<a
										onClick={(e) => e.stopPropagation()}
										target="__blank"
										href="https://www.caisse-epargne.fr/cote-d-azur/particuliers/conditions-generales"
									>
										conditions tarifaires
									</a>
									{' '}en vigueur dans ma Caisse d&apos;Epargne
									<FieldError name="pricingConditionsAccepted" />
								</Label>
							</div>
						</Col>
					</FormGroup>
				)
			}
		</>
	);
};

MotifFields.propTypes = {
	formData: PropTypes.shape({
		theme: PropTypes.string,
		demande: PropTypes.string,
		demandeType: PropTypes.string,
		files: PropTypes.arrayOf(PropTypes.shape({})),
		pricingConditionsAccepted: PropTypes.bool,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
};
