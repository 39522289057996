import { Link } from 'react-router-dom';
import { Container, Navbar } from 'reactstrap';
import React from 'react';
import Image from './Image';

import cecazLogo from '../images/logo-cecaz-RGB.png';

const NavBar = () => (
	<Navbar className="bg-white border-bottom border-grey" fixed="top" light>
		<Container className="h-100">
			<Link className="navbar-brand h-100 p-0" to="/">
				<Image className="h-100" src={cecazLogo} alt="logo Caisse d'Epargne Côte d'Azur" />
			</Link>
		</Container>
	</Navbar>
);

export default NavBar;
