import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';
import Image from '../../Image';

// import adresse0 from '../../../images/adresse0.png';
import adresse1 from '../../../images/adresse1.png';
import adresse2 from '../../../images/adresse2.png';
import adresse3 from '../../../images/adresse3.png';
import adresse4 from '../../../images/adresse4.png';
import adresse5 from '../../../images/adresse5.png';
import adresse6 from '../../../images/adresse6.png';
import adresse7 from '../../../images/adresse7.png';
import adresse8 from '../../../images/adresse8.png';
// import adresse0p from '../../../images/adresse0.webp';
import adresse1p from '../../../images/adresse1.webp';
import adresse2p from '../../../images/adresse2.webp';
import adresse3p from '../../../images/adresse3.webp';
import adresse4p from '../../../images/adresse4.webp';
import adresse5p from '../../../images/adresse5.webp';
import adresse6p from '../../../images/adresse6.webp';
import adresse7p from '../../../images/adresse7.webp';
import adresse8p from '../../../images/adresse8.webp';

import './Faq.scss';

export const FormFaqModificationAdresse = () => (
	<Row className="faq FormFaqModificationAdresse">
		<Col xs="12">
			<h2>Comment modification de votre adresse postale principale en ligne ?</h2>

			<p>
				Afin de modifier votre adresse principale en ligne,
				je vous invite à réaliser <strong>les étapes suivantes</strong> :
			</p>
			<ol className="ol-steps">
				{/* <li>
					<Image src={adresse0} webp={adresse0p} alt="Etape 1" height="380" />
					<p>
						Une fois connecté, cliquez sur « Plus » puis
						<br /> sur « Profil et données personnelles »
					</p>
				</li> */}
				<li>
					<Image src={adresse1} webp={adresse1p} alt="Etape 1" />
					<p>
						Cliquez sur « Adresse »
					</p>
				</li>
				<li>
					<Image src={adresse2} webp={adresse2p} alt="Etape 2" />
					<p>Cliquez sur « Continuer »</p>
				</li>
				<li>
					<Image src={adresse3} webp={adresse3p} alt="Etape 3" />
					<p>Cliquez sur « J&apos;ai compris »</p>
				</li>
				<li>
					<Image src={adresse4} webp={adresse4p} alt="Etape 4" />
					<p>
						Validez<br />
						l&apos;authentification par<br />
						<u>Sécur&apos;Pass</u> ou <u>Sms</u>
					</p>
				</li>
				<li>
					<Image src={adresse5} webp={adresse5p} alt="Etape 5" />
					<p>
						Cliquez sur<br />
						« Ajouter un justificatif »
					</p>
				</li>
				<li>
					<Image
						src={adresse6}
						webp={adresse6p}
						alt="Etape 6"
					/>
					<p>
						Sélectionnez le justificatif que<br />
						vous voulez	transmettre puis<br />
						cliquez sur « Continuer »
					</p>
				</li>
				<li>
					<Image
						src={adresse7}
						webp={adresse7p}
						alt="Etape 7"
					/>
					<p>
						Cliquez sur « ajouter » puis sur<br />
						« Transmettre » et enfin,<br />
						« J&apos;ai	transmis tous mes justificatifs  »
					</p>
				</li>
				<li>
					<Image src={adresse8} webp={adresse8p} alt="Etape 8" />
					<p>
						Le document est transmis, <br />
						cliquez sur « Ok »
					</p>
				</li>
			</ol>

			<p>
				Les documents pouvant être transmis comme justificatif de domicile sont les suivants :
			</p>
			<ul>
				<li>Avis de Situation Déclarative à l&apos;impôt sur le Revenu,</li>
				<li>Avis imposition/non imposition,</li>
				<li>Facture d&apos;électricité ou échéancier,</li>
				<li>Facture d&apos;eau ou échéancier,</li>
				<li>Facture de téléphone ou échéancier,</li>
				<li>Facture de fournisseur internet ou échéancier,</li>
				<li>Facture de gaz ou échéancier,</li>
				<li>Quittance de loyer,</li>
				<li>Taxe d&apos;habitation,</li>
				<li>Taxe foncière.</li>
			</ul>
		</Col>
	</Row>
);
