import { ListGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { FieldError } from 'react-jsonschema-form-validation';

const errorMessages = {
	required: ({ field }) => {
		if (/.type$/.test(field)) {
			return 'Type de fichier invalide.';
		}
		if (/.name/.test(field)) {
			return 'Nom du fichier invalide.';
		}
		return null;
	},
	enum: ({ field }) => {
		if (/.type$/.test(field)) {
			return 'Type de fichier invalide.';
		}
		return null;
	},
};

const File = ({ name, index, onDelete }) => (
	<li className="file">
		<div>
			{name}
			<FieldError
				errorMessages={errorMessages}
				name={`files.${index}.*`}
			/>
		</div>
		<span className="i">
			<FaTimes size={24} onClick={onDelete} />
		</span>
	</li>
);

const FileList = (props) => {
	const { files, onDelete } = props;
	return (
		<div className="selected-files">
			{
				files.length
					? (
						<ListGroup
							onClick={(event) => event.stopPropagation()}
						>
							{
								files.map((file, index) => (
									<File
										media={file}
										key={file.name}
										index={index}
										name={file.name}
										onDelete={() => onDelete(index)}
									/>
								))
							}
						</ListGroup>
					)
					: <p>Aucun fichier choisi.</p>
			}
		</div>
	);
};

File.propTypes = {
	index: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired,
};

FileList.propTypes = {
	onDelete: PropTypes.func.isRequired,
	files: PropTypes.arrayOf(File).isRequired,
};

export default FileList;
