export const defaultMessage = () => 'Ce champ est invalide.';

export const constant = () => defaultMessage();

export const enumeration = () => defaultMessage();

export const maxItems = ({ params }) => `${params.limit} éléments sont autorisés au maximum.`;

export const maxTotalSize = () => 'L\'ensemble des pièces jointes dépasse la taille limite autorisée (10Mo).';

export const minLength = ({ params }) => `Ce champ doit contenir au minimum ${params.limit} caractères`;

export const maxLength = ({ params }) => `Ce champ contient trop de caractères : maximun autorisé : ${params.limit}`;

export const pattern = () => defaultMessage();

export const required = () => 'Ce champ est requis.';

export default {
	const: constant,
	constant,
	defaultMessage,
	enum: enumeration,
	enumeration,
	maxItems,
	maxTotalSize,
	minLength,
	maxLength,
	pattern,
	required,
};
