import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';
import Image from '../../Image';
import { TitleDesktopMobile } from '../../TitleDesktopMobile';

import attestationOrdinateur from '../../../images/attestation-assurance-1.png';
import attestationOrdinateurP from '../../../images/attestation-assurance-1.webp';
import attestationOrdinateur2 from '../../../images/attestation-assurance-2.png';
import attestationOrdinateur2P from '../../../images/attestation-assurance-2.webp';
import attestationOrdinateur3 from '../../../images/attestation-assurance-3.png';
import attestationOrdinateur3P from '../../../images/attestation-assurance-3.webp';

import attestationMobile1 from '../../../images/attestation-assurance-m-1.png';
import attestationMobile2 from '../../../images/attestation-assurance-m-2.png';
import attestationMobile3 from '../../../images/attestation-assurance-m-3.png';
import attestationMobile4 from '../../../images/attestation-assurance-m-4.png';
// import attestationMobile5 from '../../../images/attestation-assurance-m-5.png';
import attestationMobile1P from '../../../images/attestation-assurance-m-1.webp';
import attestationMobile2P from '../../../images/attestation-assurance-m-2.webp';
import attestationMobile3P from '../../../images/attestation-assurance-m-3.webp';
import attestationMobile4P from '../../../images/attestation-assurance-m-4.webp';
// import attestationMobile5P from '../../../images/attestation-assurance-m-5.webp';

export const FormFaqAssuranceAttestation = () => (
	<Row className="faq FormFaqAssuranceAttestation">
		<Col xs="12">
			<h2> Comment obtenir une attestation sur son contrat d&apos;assurance</h2>
			<p>
				Pour réaliser cette opération
				en toute autonomie,
				je vous invite à réaliser les <strong>étapes suivantes</strong> :
			</p>
			<section className="para pb-4">
				<TitleDesktopMobile desktop />
				<ol className="ol-steps">
					<li>
						<Image
							alt="Etape 1"
							src={attestationOrdinateur}
							webp={attestationOrdinateurP}
						/>
						<p>
							Cliquez sur le menu « Assurances »<br />
							et sélectionnez un de vos contrats
						</p>
					</li>
					<li>
						<Image
							alt="Etape 2"
							src={attestationOrdinateur2}
							webp={attestationOrdinateur2P}
						/>
						<p>
							Cliquez sur <br />
							« Vos documents et attestations »<br />
						</p>
					</li>
					<li>
						<Image
							alt="Etape 3"
							src={attestationOrdinateur3}
							webp={attestationOrdinateur3P}
						/>
						<p>
							La liste des différentes attestations apparait
							<br />
							Choisissez celle que vous souhaitez télécharger
						</p>
					</li>
				</ol>
			</section>
			<section className="para pb-4">
				<TitleDesktopMobile mobile />
				<ol className="ol-steps">
					<li>
						<Image
							alt="Etape 1"
							src={attestationMobile1}
							webp={attestationMobile1P}
						/>
						<p>
							Connectez-vous à votre<br /> application bancaire
						</p>
					</li>
					<li>
						<Image
							alt="Etape 2"
							src={attestationMobile2}
							webp={attestationMobile2P}
						/>
						<p>
							Cliquez sur « Plus » puis<br /> sur « Assurances »
						</p>
					</li>
					<li>
						<Image
							alt="Etape 3"
							src={attestationMobile3}
							webp={attestationMobile3P}
						/>
						<p>
							Choisissez un de vos <br /> contrats d&apos;assurance
						</p>
					</li>
					<li>
						<Image
							alt="Etape 4"
							src={attestationMobile4}
							webp={attestationMobile4P}
						/>
						<p>
							Choisissez l&apos;option
							<br />
							« Obtenir une attestation »
						</p>
					</li>
				</ol>
			</section>
		</Col>
	</Row>
);
