import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';

import './lib/polyfills';
import './lib/rollbar';
import App from './App';

const { REACT_APP_VERSION, REACT_APP_BUILD_ID } = process.env;
const { REACT_APP_MATOMO_URL, REACT_APP_MATOMO_SITE_ID } = process.env;

const getCookieConsentRemoved = () => {
	const cookiePattern = new RegExp(`(^|;)[ ]*${'mtm_consent_removed'}=([^;]*)`); const
		cookieMatch = cookiePattern.exec(document.cookie);
	return cookieMatch ? window.decodeURIComponent(cookieMatch[2]) : 0;
};

const matomoInstance = createInstance({
	urlBase: REACT_APP_MATOMO_URL,
	siteId: REACT_APP_MATOMO_SITE_ID,
	trackerName: 'matomo',
	disabled: getCookieConsentRemoved(),
});

ReactDOM.render(
	<React.StrictMode>
		<MatomoProvider value={matomoInstance}>
			<App />
		</MatomoProvider>
	</React.StrictMode>, document.getElementById('root'),
);

// eslint-disable-next-line no-console
console.info(`ℹ️ v${REACT_APP_VERSION}/${REACT_APP_BUILD_ID}`);
