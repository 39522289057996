import * as demandeTypeSchema from '../demande/demande-type.schema';

export const clientTypes = {
	particulier: {
		label: 'Particulier',
		themes: [
			'assuranceVie',
			'comptes',
			// 'creditImmoTheme',
			'faqAssuranceAttestation',
			'faqAssuranceConsulterModifier',
			'faqAssuranceDeclarerSinistre',
			'faqCodeAccesInternet',
			'faqFaq',
			'faqModificationAdresse',
			'faqModificationEmail',
			'faqWero',
			'faqOppositionCarte',
			'faqOppositionPrelevement',
			'faqOptionInternationale',
			// 'faqRenouvellementChequier',
			'faqRibSmartphone',
			'faqVerrouillageCB',
			'faqVisualiserCode',
			'info',
			'produitsEpargne',
			'rdv',
			'rechercheDocs',
			'reclamations',
			'successions',
			// 'autres',
		],
	},
	professionnel: {
		label: 'Professionnel',
		themes: [
			'comptes',
			'faqAssuranceAttestation',
			'faqAssuranceConsulterModifier',
			'faqAssuranceDeclarerSinistre',
			'faqFaq',
			'faqVisualiserCode',
			'faqWero',
			'majDossierPro',
			'rdv',
			'rechercheDocs',
			'reclamations',
			// 'creditPro',
		],
	},
};

export const themes = {
	assuranceVie: {
		label: 'Assurance vie',
		demandeTypes: [
			'copieOperation',
			'copieSouscription',
			'copieBulletinSituation',
			'clauseBeneficiaire',
			'reeditionIfu',
			'simulationPerp',
		],
	},
	/* autres: {
		label: 'Autres',
		demandeTypes: [
			'autres',
		],
	}, */

	faqAssuranceAttestation: {
		label: 'Comment obtenir une attestation d\'assurance',
		demandeTypes: [],
		faq: true,
	},
	faqAssuranceConsulterModifier: {
		label: 'Comment consulter ou modifier son contrat d\'assurance',
		demandeTypes: [],
		faq: true,
	},
	faqAssuranceDeclarerSinistre: {
		label: 'Comment déclarer un sinistre sur son contrat d\'assurance',
		demandeTypes: [],
		faq: true,
	},
	faqCodeAccesInternet: {
		label: 'Comment obtenir une réédition du code secret de votre espace sécurisé',
		demandeTypes: [],
		faq: true,
	},
	faqFaq: {
		label: 'Comment résoudre un Problème technique via notre FAQ',
		demandeTypes: [],
		faq: true,
	},
	faqModificationAdresse: {
		label: 'Comment modifier son adresse postale',
		demandeTypes: [],
		faq: true,
	},
	faqModificationEmail: {
		label: 'Comment modifier son adresse email',
		demandeTypes: [],
		faq: true,
	},
	faqOptionInternationale: {
		label: 'Comment souscrire à l\'option internationale',
		demandeTypes: [],
		faq: true,
	},
	faqOppositionCarte: {
		label: 'Comment faire une mise en opposition de votre carte bancaire',
		demandeTypes: [],
		faq: true,
	},
	faqOppositionPrelevement: {
		label: 'Comment faire une mise en opposition d\'un prélèvement',
		demandeTypes: [],
		faq: true,
	},
	// faqRenouvellementChequier: {
	// 	label: 'Comment renouveller son chéquier',
	// 	demandeTypes: [],
	// 	faq: true,
	// },
	faqRibSmartphone: {
		label: 'Comment ajouter un RIB depuis mon Smartphone',
		demandeTypes: [],
		faq: true,
	},
	faqVerrouillageCB: {
		label: 'Comment verrouiller temporairement ma carte bleue',
		demandeTypes: [],
		faq: true,
	},
	faqVisualiserCode: {
		label: 'Comment visualiser temporairement mon code secret de Carte Bancaire',
		demandeTypes: [],
		faq: true,
	},
	faqWero: {
		label: 'Comment envoyer / recevoir de l\'argent avec WERO',
		demandeTypes: [],
		faq: true,
	},
	comptes: {
		label: 'Comptes',
		demandeTypes: [
			// 'cloture',
			//'incidentDistributeur',
			'incidentCheque',
			//'incidentSecurExpress',
			'mobility',
		],
	},
	// creditImmoTheme: {
	// 	label: 'Crédit immobilier',
	// 	demandeTypes: [
	// 		'versementFonds',
	// 	],
	// },
	// creditPro: {
	// 	label: 'Crédit professionnel',
	// 	demandeTypes: [
	// 		'versementFondsPro',
	// 	],
	// },
	info: {
		label: 'Demande d\'information',
		demandeTypes: [
			'infoCreditImmo',
			'infoCreditConso',
			'infoAssuranceHabitation',
			'infoAssuranceAuto',
			'infoPrevoyance',
			'infoGarantieSante',
			'infoProduitAssuranceVie',
			'infoProduitsEpargneOuAutres',
			'infoTitresPEA',
			'infoAutre',
		],
	},
	majDossierPro: {
		label: 'Mise à jour du dossier',
		demandeTypes: [
			'bilanAnnuel',
			'statutCniDomicile',
		],
	},
	// majDossierPar: {
	// 	label: 'Mise à jour du dossier',
	// 	demandeTypes: [
	// 		'justificatifIdentiteDomicileRevenus',
	// 	],
	// },
	produitsEpargne: {
		label: 'Produits d\'épargne',
		demandeTypes: [
			'demandeInfoEpargne',
			'livretEpargne',
		],
	},
	rdv: {
		label: 'Demande de rendez-vous',
		demandeTypes: [
			'rdv',
		],
		faq: true,
	},
	rechercheDocs: {
		label: 'Recherche de documents',
		demandeTypes: [
			'chequeEmis',
			'chequeEncaisse',
			'offrePret',
			'historiqueCompte',
			'quittance',
		],
	},
	reclamations: {
		label: 'Réclamations',
		demandeTypes: [
			'assuranceIard',
			'assuranceVie',
			'comptes',
			'compteTitres',
			'produitsEpargne',
			'succession',
			'tarification',
			'creditImmo',
			'creditConso',
		],
	},
	successions: {
		label: 'Successions',
		demandeTypes: [
			'acteDeces',
		],
	},
};

export const demandeTypes = {
	acteDeces: {
		label: 'Enregistrement de l\'acte de décés',
		jsonSchema: demandeTypeSchema.acteDeces,
	},
	assuranceIard: {
		label: 'Assurances des biens et des personnes IARD',
	},
	assuranceVie: {
		customPlaceholder: 'Saisissez le ou les numéros de contrat concerné(s) par la demande.',
		label: 'Assurance-vie',
	},
	/*autres: {
		customMotif: true,
		label: 'Autres',
		jsonSchema: demandeTypeSchema.motifCustom,
	},*/
	bilanAnnuel: {
		label: 'Transmission du bilan annuel',
	},
	chequeEmis: {
		label: 'Chèque émis',
		jsonSchema: demandeTypeSchema.rechercheDocsMotifs,
	},
	chequeEncaisse: {
		label: 'Chèque encaissé',
		jsonSchema: demandeTypeSchema.rechercheDocsMotifs,

	},
	clauseBeneficiaire: {
		label: 'Recherche de clause bénéficiaire',
		jsonSchema: demandeTypeSchema.assuranceVie,
	},
	// cloture: {
	// 	label: 'Clôture',
	// },
	//faqCode: true,
	// faqAssuranceConsulterModifier: true,
	// faqAssuranceDeclarerSinistre: true,
	// faqVisualiserCodeord: true,
	// faqCoord: true,
	// //faqChequier: true,
	// faqOppositionCarte: true,
	// faqOppositionPrelevement: true,
	// faqRIBSmartphone: true,
	// faqVerrouillageCB: true,
	comptes: {
		label: 'Comptes',
	},
	compteTitres: {
		label: 'Compte titres',
	},
	copieBulletinSituation: {
		label: 'Copie bulletin de situation',
		jsonSchema: demandeTypeSchema.assuranceVie,
	},
	copieOperation: {
		label: 'Demande d\'information (rachat, versement, avenant, …)',
		jsonSchema: demandeTypeSchema.assuranceVie,
	},
	copieSouscription: {
		label: 'Copie du contrat de souscription',
		jsonSchema: demandeTypeSchema.assuranceVie,
	},
	creditConso: {
		label: 'Crédit consommation',
	},
	creditImmo: {
		label: 'Crédit immobilier',
	},
	demandeInfoEpargne: {
		label: 'Demande d\'informations (PEL, LEP, BONS au porteur, EAI )',
	},
	historiqueCompte: {
		label: 'Historique de compte',
		jsonSchema: demandeTypeSchema.rechercheDocsMotifs,
	},
	incidentCheque: {
		label: 'Régularisation incident sur chèque',
		jsonSchema: demandeTypeSchema.incidentCheque,
	},
	// incidentDistributeur: {
	// 	label: 'Incident sur distributeur',
	// 	jsonSchema: demandeTypeSchema.incidentDistributeur,
	// },
	// incidentSecurExpress: {
	// 	label: 'Incident dépôt Secur\'Express',
	// 	jsonSchema: demandeTypeSchema.incidentSecurExpress,
	// },
	info: {
		customMotif: true,
		label: 'Demande d\'information',
		jsonSchema: demandeTypeSchema.motifCustom,
	},
	infoCreditImmo: { label: 'Crédit Immobilier' },
	infoCreditConso: { label: 'Crédit Consommation' },
	infoAssuranceHabitation: { label: 'Assurance Habitation' },
	infoAssuranceAuto: { label: 'Assurance Auto' },
	infoPrevoyance: { label: 'Prévoyance ' },
	infoGarantieSante: { label: 'Garantie Santé' },
	infoProduitAssuranceVie: { label: 'Produit assurance vie' },
	infoProduitsEpargneOuAutres: { label: 'Produits épargne (réglementé) ou autres, instrument financier' },
	infoTitresPEA: {
		label: 'Toutes opérations sur Titres / PEA et parts sociales',
	},
	infoAutre: {
		label: 'Autre',
	},
	justificatifIdentiteDomicileRevenus: {
		label: 'Justificatif d\'identité – domicile – revenus',
	},
	livretEpargne: {
		label: 'Livret Epargne Populaire - avis d\'imposition',
	},
	mobility: {
		label: 'Suivre le changement de ma domiciliation bancaire ( Mandat Macron)',
	},
	offrePret: {
		label: 'Offre de prêt',
		jsonSchema: demandeTypeSchema.rechercheDocsMotifs,
	},
	produitsEpargne: {
		label: 'Produits épargne',
	},
	quittance: {
		label: 'Quittance',
		jsonSchema: demandeTypeSchema.rechercheDocsMotifs,
	},
	rdv: {
		label: 'Demande de rendez-vous',
	},
	reeditionIfu: {
		label: 'Réédition IFU (Imprimé Fiscal Unique)',
		jsonSchema: demandeTypeSchema.assuranceVie,
	},
	simulationPerp: {
		label: 'Simulation rente PERP',
		jsonSchema: demandeTypeSchema.assuranceVie,
	},
	statutCniDomicile: {
		label: 'Statut',
	},
	succession: {
		label: 'Succession',
	},
	tarification: {
		label: 'Tarification',
	},
	// versementFonds: {
	// 	label: 'Demande de versement de fonds',
	// 	jsonSchema: demandeTypeSchema.versementFonds,
	// },
	// versementFondsPro: {
	// 	label: 'Demande de versement de fonds',
	// 	jsonSchema: demandeTypeSchema.versementFondsPro,
	// },
};
//
// export const agencies = [
// 	'Antibes croix rouge',
// 	'Antibes la fontonne',
// 	'Antibes soleau',
// 	'Aups',
// 	'Bandol',
// 	'Banque du dirigeant',
// 	'Barjols',
// 	'Beaulieu',
// 	'Beausoleil',
// 	'Biot',
// 	'Brignoles',
// 	'Cagnes',
// 	'Cannes Alexandre III',
// 	'Cannes Carnot',
// 	'Cannes casino des fleurs',
// 	'Cannes Forville',
// 	'Cannes la Bocca',
// 	'Cannes republique',
// 	'Carces',
// 	'Carqueiranne',
// 	'Carros le neuf',
// 	'Cavalaire',
// 	'Centre patrimonial Cannes',
// 	'Centre patrimonial Nice',
// 	'Centre patrimonial Saint-Raphael',
// 	'Centre patrimonial Saint-Tropez',
// 	'Centre patrimonial Toulon',
// 	'Chateauneuf',
// 	'Cogolin',
// 	'Contes',
// 	'Cros de Cagnes',
// 	'Cuers',
// 	'Draguignan cazelles',
// 	'E agence',
// 	'Fayence',
// 	'Frejus mitan',
// 	'Frejus petit theatre',
// 	'Gareoult',
// 	'Golfe Juan',
// 	'Gonfaron',
// 	'Grasse jeu de ballon',
// 	'Grasse saint jacques',
// 	'Hyeres gare',
// 	'Hyeres pyanet',
// 	'Hyeres ville',
// 	'Juan les pins',
// 	'La cadiere',
// 	'La colle sur loup',
// 	'La coupiane',
// 	'La crau',
// 	'La farlede',
// 	'La garde',
// 	'La londe',
// 	'La seyne arche',
// 	'La seyne ville',
// 	'La valette',
// 	'Le beausset',
// 	'Le cannet rocheville',
// 	'Le cannet',
// 	'Le lavandou',
// 	'Le luc',
// 	'Le muy',
// 	'Le pradet',
// 	'Les arcs',
// 	'Lorgues',
// 	'Mandelieu',
// 	'Mar vivo les sablettes',
// 	'Menton',
// 	'Montauroux',
// 	'Mouans sartoux',
// 	'Nice arenas',
// 	'Nice arson',
// 	'Nice carras',
// 	'Nice cimiez',
// 	'Nice ferber',
// 	'Nice gambetta france',
// 	'Nice gambetta cessole',
// 	'Nice gambetta rossini',
// 	'Nice garibaldi',
// 	'Nice joseph garnier',
// 	'Nice la trinite',
// 	'Nice le stade',
// 	'Nice magnan',
// 	'Nice malaussena',
// 	'Nice massena',
// 	'Nice medecin',
// 	'Nice notre dame',
// 	'Nice republique',
// 	'Nice saint isidore',
// 	'Nice sainte marguerite',
// 	'Nice st barthelemy',
// 	'Nice st maurice',
// 	'Nice st roch',
// 	'Ollioules',
// 	'Pegomas',
// 	'Peymeinade',
// 	'Pierrefeu',
// 	'Pignans',
// 	'Plan de grasse',
// 	'Puget sur argens',
// 	'Puget theniers',
// 	'Puget ville',
// 	'Rians',
// 	'Rocbaron',
// 	'Roquebrune cap martin',
// 	'Roquebrune-sur-argens',
// 	'Roquefort',
// 	'Saint aygulf',
// 	'Saint cyr',
// 	'Saint jeannet',
// 	'Saint mandrier',
// 	'Saint maximin',
// 	'Saint raphael',
// 	'Saint tropez',
// 	'Saint zacharie',
// 	'Sainte maxime',
// 	'Salernes',
// 	'Sanary',
// 	'Six fours',
// 	'Sollies pont',
// 	'Sollies toucas',
// 	'Sospel',
// 	'St laurent du var',
// 	'Tende',
// 	'Toulon aguillon',
// 	'Toulon claret',
// 	'Toulon la loubiere',
// 	'Toulon la serinette',
// 	'Toulon les routes',
// 	'Toulon mourillon',
// 	'Toulon pont du las',
// 	'Toulon strasbourg',
// 	'Toulon vauban',
// 	'Toulon villa noemi',
// 	'Toulon zola',
// 	'Trans',
// 	'Val de mougins',
// 	'Valbonne',
// 	'Valescure',
// 	'Vallauris',
// 	'Vence',
// 	'Vidauban',
// 	'Villefranche-sur-mer',
// 	'Villeneuve-loubet',
// 	'Vinon',
// ];
