import {
	Col,
	Row,
} from 'reactstrap';
import { TitleDesktopMobile } from '../../TitleDesktopMobile';
import Image from '../../Image';

import consulterOrdinateur1Png from '../../../images/assurance-consulter-1.png';
import consulterOrdinateur2Png from '../../../images/assurance-consulter-2.png';
import consulterOrdinateur1Webp from '../../../images/assurance-consulter-1.webp';
import consulterOrdinateur2Webp from '../../../images/assurance-consulter-2.webp';

import consulterMobile1 from '../../../images/assurance-consulter-m-1.png';
import consulterMobile2 from '../../../images/assurance-consulter-m-2.png';
import consulterMobile3 from '../../../images/assurance-consulter-m-3.png';
import consulterMobile4 from '../../../images/assurance-consulter-m-4.png';
// import consulterMobile5 from '../../../images/assurance-consulter-m-5.png';
import consulterMobile1P from '../../../images/assurance-consulter-m-1.webp';
import consulterMobile2P from '../../../images/assurance-consulter-m-2.webp';
import consulterMobile3P from '../../../images/assurance-consulter-m-3.webp';
import consulterMobile4P from '../../../images/assurance-consulter-m-4.webp';
// import consulterMobile5P from '../../../images/assurance-consulter-m-5.webp';

export const FormFaqAssuranceConsulterModifier = () => (
	<Row className="faq FormFaqAssuranceConsulterModifier">
		<Col xs="12">
			<h2>Comment consulter ou modifier son contrat d&apos;assurance</h2>
			<p>
				Pour réaliser cette opération en toute autonomie,
				je vous invite à réaliser <strong>les étapes suivantes</strong> :
			</p>
			<section className="para pb-4">
				<TitleDesktopMobile desktop />
				<ol className="ol-steps">
					<li>
						<Image src={consulterOrdinateur1Png} webp={consulterOrdinateur1Webp} alt="Etape 1 et 2 : se connecter, cliquer sur assurance puis gestion des assurances" />
						<p>
							Cliquez sur le menu « Assurances »<br />
							et sélectionnez un de vos contrats
						</p>
					</li>
					<li>
						<Image src={consulterOrdinateur2Png} webp={consulterOrdinateur2Webp} alt="Etape 3 : la liste des différents choix apparait" />
						<p>
							La liste des différentes fonctionnalités apparaît,<br />
							et vous pouvez consulter ou modifier votre assurance.
						</p>
					</li>
				</ol>
			</section>
			<section className="para pb-4">
				<TitleDesktopMobile mobile />
				<ol className="ol-steps">
					<li>
						<Image className="noShadow" src={consulterMobile1} webp={consulterMobile1P} alt="Etape 1" />
						<p>
							Connectez-vous à votre<br /> application bancaire
						</p>
					</li>
					<li>
						<Image className="noShadow" src={consulterMobile2} webp={consulterMobile2P} alt="Etape 2" />
						<p>
							Cliquez sur « Plus » puis<br /> sur « Assurances »
						</p>
					</li>
					<li>
						<Image className="noShadow" src={consulterMobile3} webp={consulterMobile3P} alt="Etape 3" />
						<p>
							Choisissez le contrat<br /> à consulter ou modifier
						</p>
					</li>
					<li>
						<div>
							<Image className="noShadow" src={consulterMobile4} webp={consulterMobile4P} alt="Etape 4" />
							<p>
								Choisissez consulter ou modifier
							</p>
						</div>
					</li>
					{/* <li>
						<div>
							<Image
								className="noShadow" src={consulterMobile5} webp={consulterMobile5P} alt="Etape 5 "
							/>
							<p>
								Consulter ou<br />modifier votre contrat<br /> d&apos;assurance
							</p>
						</div>
					</li> */}
				</ol>
			</section>
		</Col>
	</Row>
);
