import Helmet from 'react-helmet';
// import { Link } from 'react-router-dom';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Footer } from '../components/Footer';
import { HeadSimple } from '../components/HeadSimple';

export const Mentions = () => (
	<div>
		<Helmet>
			<title>macaissedepargnecotedazurmerepond.fr – Mentions Légale</title>
		</Helmet>
		<HeadSimple className="head-mentions">
			<header className="header">
				<div className="header-title">
					<Container>
						<Row>
							<Col>
								<strong className="xl-title">MENTIONS LÉGALES</strong>
							</Col>
						</Row>
						<Row className="bottom-text">
							<Col>
								<h1 className="md-title">
									Caisse d&apos;Epargne Côte d&apos;Azur
									<strong>Ma Caisse d&apos;Epargne Côte d&apos;Azur me répond</strong>
								</h1>
							</Col>
						</Row>
					</Container>
				</div>
			</header>
		</HeadSimple>
		<Container tag="main" className="mentions-content py-4">
			<Row tag="article">
				<Col>
					<h2>EDITEUR DU SITE</h2>
					<p>
						Ce site est édité par la Caisse d&apos;Epargne et de
						Prévoyance Côte d&apos;Azur, ci-après « l&apos;éditeur »
					</p>
					<table className="table table-bordered">
						<tbody>
							<tr>
								<th scope="row">Raison sociale :</th>
								<td>
									Caisse d&apos;Epargne et de Prévoyance Côte d&apos;Azur,
									société anonyme coopérative à directoire et conseil
									d&apos;orientation et de surveillance, régie par les articles L.512-85 et
									suivants du Code monétaire et financier – Capital social 515.033.520
									euros – 455, promenade des Anglais, 06200 Nice – 384
									402 871 RCS NICE – Intermédiaire
									d&apos;assurance, immatriculé à l&apos;ORIAS sous le n° 07 002 199.
									<br />
									<br />
									Titulaire de la carte professionnelle Transactions sur
									immeubles et fonds de commerce, sans perception d&apos;effets ou
									valeurs, n° CPI 0605 2017 000 019 152 délivrée par la CCI Nice-Côte d&apos;Azur,
									garantie par la Compagnie Européenne de Garanties et Cautions, 16 rue Hoche,
									Tour KUPKA B, TSA 39999, 92919 LA DEFENSE Cedex.
								</td>
							</tr>
							<tr>
								<th scope="row">Forme sociale :</th>
								<td>
									Société anonyme coopérative à directoire et conseil
									d&apos;orientation et de surveillance
								</td>
							</tr>
							<tr>
								<th scope="row">Siège social : </th>
								<td>455, promenade des Anglais, 06200 Nice.</td>
							</tr>
							<tr>
								<th scope="row">N° SIREN :</th>
								<td>384402871</td>
							</tr>
							<tr>
								<th scope="row">N° ind. d&apos;ident. Intracommunautaire : </th>
								<td>FR6938440287100543</td>
							</tr>
							<tr>
								<th scope="row">Directeur de publication :</th>
								<td>Claude VALADE</td>
							</tr>
							<tr>
								<th scope="row">Contact :</th>
								<td>
									cecaz-b-expressionclient-src@cecaz.caisse-epargne.fr
									<br />
									04 93 18 40 00
								</td>
							</tr>
							<tr>
								<th scope="row">Hébergeur</th>
								<td>Dénomination : Amazon Web Services - Paris <br />
									Adresse : 31 place des Corolles, 92098 PARIS – LA DEFENSE Cedex
								</td>
							</tr>
						</tbody>
					</table>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<p>
						L&apos;Editeur est un établissement
						de crédit de droit français agréé en qualité de banque prestataire
						de services d&apos;investissement,
						conformément aux dispositions du Code monétaire et financier et
						contrôlée par la Banque Centrale Européenne
						(Sonnemannstrasse 20, D-60314 Frankfurt-am-Main, Allemagne).
					</p>
					<p>
						L&apos;Editeur est adhérent auprès de la FBF - Fédération Bancaire Française,
						organisation professionnelle représentative de la profession bancaire.
						En matière de produits d&apos;assurance,
						l&apos;Editeur agit en qualité d&apos;intermédiaire en assurance immatriculé à
						l&apos;ORIAS sous le n°07 002 199
					</p>
				</Col>
			</Row>
		</Container>
		<Footer />
	</div>
);
