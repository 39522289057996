import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';

const Socials = () => (
	<div className="footer-socials">
		<a
			className="social-icon"
			href="https://www.facebook.com/caissedepargnecotedazur"
			title="Caisse d'Epargne Côte d'Azur sur Facebook"
		>
			<FaFacebookF size={14} />
		</a>
		<a
			className="social-icon ml-1"
			href="https://twitter.com/CE_CotedAzur"
			title="Caisse d'Epargne Côte d'Azur sur Twitter"
		>
			<FaTwitter size={14} />
		</a>
		<a
			className="social-icon ml-1"
			href="https://www.linkedin.com/company/3107203/"
			title="Caisse d'Epargne Côte d'Azur sur LinkedIn"
		>
			<FaLinkedinIn size={14} />
		</a>
	</div>
);

export default Socials;
