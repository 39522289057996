import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import React from 'react';
import {
	Button,
	Col,
	FormGroup,
	Label,
} from 'reactstrap';
import { Field, FieldError } from 'react-jsonschema-form-validation';

import FileList from './file-list';

const errorMessages = {
	maxItems: ({ params }) => `Pièces jointes trop nombreuses (maximum autorisé: ${params.limit}).`,
};

export const CustomFile = ({
	onChange, files,
}) => {
	const handleDelete = (index) => {
		onChange({
			files: files.filter((f, i) => i !== index),
		});
	};

	const handleDrop = ({ accepted }) => {
		onChange({
			files: [...files, ...accepted],
		});
	};

	return (
		<FormGroup className="row">
			<Label className="col-md-3 col-lg-4">Pièce(s) jointe(s) :</Label>
			<Col md="7" lg="6">
				<Field
					component={Dropzone}
					className="drop-file"
					name="files"
					onDrop={(accepted, rejected) => { handleDrop({ accepted, rejected }); }}
				>
					<FileList
						files={files}
						onDelete={handleDelete}
					/>
					{
						files.length < 5
							? (
								<Button type="button" className="wn-dropzone-text text-white" color="secondary" block>
									Déposer un fichier ou cliquer<br />
									ici pour en sélectionner un.
								</Button>
							) : null
					}
				</Field>
				<FieldError
					errorMessages={errorMessages}
					name="files"
				/>
				<p className="file-mentions">
					Jusqu&apos;à 5 pièces jointes. Les extensions acceptées sont les suivantes
					.doc / .docx / .pdf / .jpg / .png. L&apos;ensemble des fichiers déposé
					doit peser moins de 10 Mo.
				</p>
			</Col>
		</FormGroup>
	);
};

CustomFile.propTypes = {
	onChange: PropTypes.func.isRequired,
	files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
