import uniq from 'lodash.uniq';

import { clientTypes, demandeTypes, themes } from '../data/data';

const file = {
	type: 'object',
	properties: {
		name: { type: 'string', minLength: 3 },
		type: {
			type: 'string',
			enum: [
				'image/png',
				'image/jpeg',
				'application/msword',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				'application/pdf',
			],
		},
	},
	required: [
		'name',
		'type',
	],
};

const demandeSchema = {
	type: 'object',
	properties: {
		demande: { type: 'string', minLength: 2, maxLength: 1000 },
		files: {
			items: file,
			maxItems: 5,
			maxTotalSize: 10 * 1000000, // (10Mo)
			type: 'array',
		},
	},
	required: [
		'demande',
		'files',
	],
};

function getDemandeSchema(clientType, theme, demandeType) {
	const filteredClientTypes = (clientType && clientTypes[clientType])
		? [clientType] : Object.keys(clientTypes);

	const filteredThemes = (theme && themes[theme])
		? [theme]
		: filteredClientTypes.reduce((acc, ct) => [...acc, ...clientTypes[ct].themes], []);

	const filteredDemandeTypes = (demandeType && demandeTypes[demandeType])
		? [demandeType]
		: filteredThemes.reduce((acc, t) => [...acc, ...themes[t].demandeTypes], []);

	const demandeTypeObject = demandeType && demandeTypes[demandeType] && demandeTypes[demandeType];
	const demandeTypeSchema = (demandeTypeObject && demandeTypeObject.jsonSchema)
		|| {
			properties: {},
			required: [],
		};

	return {
		type: 'object',
		properties: {
			demandeType: {
				type: 'string',
				enum: uniq(filteredDemandeTypes),
			},
			theme: {
				type: 'string',
				enum: uniq(filteredThemes),
			},
			vousEtes: {
				type: 'string',
				enum: uniq(filteredClientTypes),
			},
			...demandeSchema.properties,
			...demandeTypeSchema.properties,
		},
		required: [
			'demandeType',
			'theme',
			'vousEtes',
			...demandeSchema.required,
			...demandeTypeSchema.required,
		],
	};
}

export default getDemandeSchema;
