import {
	Col,
	Row,
} from 'reactstrap';
import { TitleDesktopMobile } from '../../TitleDesktopMobile';
import Image from '../../Image';

import sinitreOrdinateur1 from '../../../images/sinistre-desktop-1.png';
import sinitreOrdinateur2 from '../../../images/sinistre-desktop-2.png';
import sinitreOrdinateur3 from '../../../images/sinistre-desktop-3.png';
import sinitreOrdinateur1p from '../../../images/sinistre-desktop-1.webp';
import sinitreOrdinateur2p from '../../../images/sinistre-desktop-2.webp';
import sinitreOrdinateur3p from '../../../images/sinistre-desktop-3.webp';

import sinitreSmartphone1 from '../../../images/sinistre-mobile-1.png';
import sinitreSmartphone2 from '../../../images/sinistre-mobile-2.png';
import sinitreSmartphone3 from '../../../images/sinistre-mobile-3.png';
import sinitreSmartphone4 from '../../../images/sinistre-mobile-4.png';
import sinitreSmartphone1p from '../../../images/sinistre-mobile-1.webp';
import sinitreSmartphone2p from '../../../images/sinistre-mobile-2.webp';
import sinitreSmartphone3p from '../../../images/sinistre-mobile-3.webp';
import sinitreSmartphone4p from '../../../images/sinistre-mobile-4.webp';

export const FormFaqAssuranceDeclarerSinistre = () => (
	<Row className="faq FormFaqAssuranceDeclarerSinistre">
		<Col xs="12">
			<h2>Comment déclarer un sinistre sur son contrat d&apos;assurance</h2>
			<p>
				Pour réaliser cette opération en toute autonomie,
				je vous invite à réaliser <strong>les étapes suivantes</strong> :
			</p>
			<section className="para pb-4">
				<TitleDesktopMobile desktop />
				<ol className="ol-steps">
					<li>
						<Image
							alt="Attestation depuis un ordinateur étape 1"
							className=""
							src={sinitreOrdinateur1}
							webp={sinitreOrdinateur1p}
						/>
						<p>
							Cliquez sur le menu « Assurances »<br />
							et sélectionnez un de vos contrats
						</p>
					</li>
					<li>
						<Image
							alt="Attestation depuis un ordinateur étape 2"
							className=""
							src={sinitreOrdinateur2}
							webp={sinitreOrdinateur2p}
						/>
						<p>
							Cliquez sur le bouton <br />
							« Déclarer un sinistre »
						</p>
					</li>
					<li>
						<Image
							alt="Attestation depuis un ordinateur étape 3"
							className=""
							src={sinitreOrdinateur3}
							webp={sinitreOrdinateur3p}
						/>
						<p>
							Complétez les informations demandées<br />
							dans le formulaire
						</p>
					</li>
				</ol>
			</section>
			<section className="para pb-4">
				<TitleDesktopMobile mobile />
				<ol className="ol-steps">
					<li>
						<Image alt="étape 1" className="" src={sinitreSmartphone1} webp={sinitreSmartphone1p} />
						<p>
							Connectez vous à votre<br /> application mobile.
						</p>
					</li>
					<li>
						<Image alt="étape 2" className="" src={sinitreSmartphone2} webp={sinitreSmartphone2p} />
						<p>
							Dans le menu <br />
							cliquez sur « ASSURANCES »
						</p>
					</li>
					<li>
						<Image alt="étape 3" className="" src={sinitreSmartphone3} webp={sinitreSmartphone3p} />
						<p>
							Selectionnez<br /> un de vos contrats
						</p>
					</li>
					<li>
						<Image alt="étape 4" className="" src={sinitreSmartphone4} webp={sinitreSmartphone4p} />
						<p>
							Choisissez<br />« déclarer un sinistre »
						</p>
					</li>
				</ol>
			</section>
		</Col>
	</Row>
);
