import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';
import { FaArrowRight } from 'react-icons/fa';

import Image from '../../Image';

import faq from '../../../images/faq.jpg';
import faqP from '../../../images/faq.webp';

export const FormFaqFaq = () => (
	<Row className="faq FormFaqFaq">
		<Col xs="12">
			<h2>Comment résoudre un problème technique via notre FAQ</h2>
			<p>
				Vous rencontrez un problème technique,
				afin de le résoudre votre Caisse d&apos;Epargne côtes d&apos;azur met
				à votre disposition une foire aux questions.
			</p>
			<p>
				Retrouvez, dans cette FAQ,
				toutes les réponses à vos questions sur notre
				banque à distance, nos solutions mobiles….
			</p>
			<a
				href="https://www.caisse-epargne.fr/cote-d-azur/questions-frequentes/#faq"
				title="FAQ Caisse d'Epargne"
				className="mb-4 btn btn-outline-dark d-inline-flex align-items-center flex-grow-0"
			>
				<FaArrowRight className="mr-2" />
				<span>Questions fréquentes | Côte d&apos;Azur (caisse-epargne.fr)</span>
			</a>
			<Image className="mb-3 img-fluid" src={faq} webp={faqP} alt="Capture d'écran de la FAQ du site caisse d'epargne" />
		</Col>
	</Row>
);
