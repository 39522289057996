import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { themes } from '../schemas/data/data';
import { SelectStyle } from './SelectStyle';

const SelectTheme = ({
	disabled,
	options,
	onChange,
	value,
}) => {
	const optionsFormatted = useMemo(() => options
		.sort((a, b) => themes[a].label.localeCompare(themes[b].label))
		.map((t) => ({ label: themes[t].label, value: t })),
	[options]);
	return (
		<Select
			isDisabled={disabled}
			isSearchable
			isClearable
			name="theme"
			placeholder="Recherchez ou sélectionnez un thème"
			options={optionsFormatted}
			onChange={onChange}
			noOptionsMessage={() => 'Aucune thème ne correspond à votre recherche'}
			styles={SelectStyle}
			value={optionsFormatted.filter((obj) => obj.value === value)}
		/>
	);
};

SelectTheme.propTypes = {
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.string),
	value: PropTypes.string,
};
SelectTheme.defaultProps = {
	disabled: false,
	options: [],
	value: '',
};

export default SelectTheme;
