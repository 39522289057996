import PropTypes from 'prop-types';
import Select from 'react-select';
import { SelectStyle } from './SelectStyle';

const lightTheme = {
	singleValue: (base) => ({
		...base,
		fontWeight: 400,
		color: '#495057',
	}),
	placeholder: (base) => ({
		...base,
		color: '#495057',
		fontWeight: 400,
	}),
};

const SimpleSelect = ({
	boldStyle,
	disabled,
	name,
	onChange,
	options,
	placeholder,
	value,
}) => (
	<Select
		isDisabled={disabled}
		isSearchable={false}
		isClearable={false}
		name={name}
		placeholder={placeholder}
		options={options}
		onChange={onChange}
		styles={
			boldStyle ? SelectStyle : { ...SelectStyle, ...lightTheme }
		}
		value={options.filter((obj) => obj.value === value)}
	/>
);

SimpleSelect.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
	})),
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	boldStyle: PropTypes.bool,
	value: PropTypes.string,
};
SimpleSelect.defaultProps = {
	boldStyle: false,
	disabled: false,
	options: [],
	value: '',
};

export default SimpleSelect;
