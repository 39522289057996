import PropTypes from 'prop-types';
import React from 'react';

const Hint = ({
	children,
	className,
	hint,
}) => (
	<div className={`hint ${className || ''}`}>
		<div className="content">
			{hint}
			{children}
		</div>
	</div>
);

Hint.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	hint: PropTypes.string,
};

Hint.defaultProps = {
	children: null,
	hint: '',
	className: '',
};

export default Hint;
