import Ajv from 'ajv';

const customAjv = new Ajv({ allErrors: true, v5: true, $data: true });

customAjv.addKeyword('maxTotalSize', {
	validate(schema, data) {
		if (!data) { return true; }
		const totalSize = data.reduce((acc, file) => acc + file.size, 0);
		return totalSize <= schema;
	},
	errors: false,
});

export { customAjv };
