import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';

export const FormFaqWero = () => (
	<Row className="faq FormFaqWero">
		<Col xs="12">
			<h2>Comment envoyer / recevoir de l&apos;argent avec WERO</h2>

			<p>
				Vous trouverez ci-dessous les liens
				vous permettant d&apos;envoyer et de recevoir de l&apos;argent avec WERO
			</p>
			<div className="my-4">
				<h3>
					Comment envoyer de l&apos;argent vers wero :
				</h3>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube-nocookie.com/embed/Fak0S_vatK8?si=R-M1f6cxRB-Fe3b3"
					title="YouTube video player"
					// eslint-disable-next-line react/no-unknown-property
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				/>
			</div>
			<div className="my-5">
				<h3>
					Comment recevoir de l&apos;argent via wero :
				</h3>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube-nocookie.com/embed/rqNFcTixGss"
					title="YouTube video player"
					// eslint-disable-next-line react/no-unknown-property
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				/>
			</div>
			<div className="my-5 text-small">
				Liens des vidéos :
				<ul>
					<li>
						<a href="https://youtu.be/Fak0S_vatK8?si=ZIrsKOrQGQfjyRWy" target="_blank" rel="noopener noreferrer">
							Comment envoyer de l&apos;argent vers wero
						</a>
					</li>
					<li>
						<a href="https://youtu.be/rqNFcTixGss" target="_blank" rel="noopener noreferrer">
							Comment recevoir de l&apos;argent via wero
						</a>
					</li>
				</ul>
			</div>
		</Col>

	</Row>
);
