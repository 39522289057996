import React from 'react';
import { FaCheck } from 'react-icons/fa';

export const Success = () => (
	<div className="success">
		<FaCheck size={22} style={{ color: '#8cd306' }} className="i" />
		<p>Votre demande a bien été envoyée</p>
	</div>
);

export default Success;
