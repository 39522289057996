/* eslint-disable react/destructuring-assignment */
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import React from 'react';
import {
	Col,
	FormGroup,
	Input,
	Label,
} from 'reactstrap';
import { Field, FieldError } from 'react-jsonschema-form-validation';

import SwitchComponent from './switch';
import Hint from './hint';

export const ActeDeces = (props) => (
	<>
		<FormGroup className="row align-items-center">
			<Label htmlFor="reclamation-prenomDefunt" className="col-md-3 col-lg-4">Prénom du défunt* :</Label>
			<Col md="7" lg="6">
				<Field
					component={Input}
					id="reclamation-prenomDefunt"
					name="prenomDefunt"
					placeholder="Prénom"
					required
					type="text"
					value={props.formData.prenomDefunt}
				/>
				<FieldError name="prenomDefunt" />
			</Col>
		</FormGroup>
		<FormGroup className="row align-items-center">
			<Label htmlFor="reclamation-nomDefunt" className="col-md-3 col-lg-4">Nom du défunt* :</Label>
			<Col md="7" lg="6">
				<Field
					component={Input}
					id="reclamation-nomDefunt"
					name="nomDefunt"
					placeholder="Nom"
					required
					type="text"
					value={props.formData.nomDefunt}
				/>
				<FieldError name="nomDefunt" />
			</Col>
		</FormGroup>
		<FormGroup className="row align-items-center">
			<Label htmlFor="reclamation-naissanceDefunt" className="col-md-3 col-lg-4">Date de naissance du défunt* :</Label>
			<Col md="7" lg="6">
				<Field
					className="form-control"
					component={InputMask}
					mask="99/99/9999"
					id="reclamation-naissanceDefunt"
					name="naissanceDefunt"
					required
					placeholder="Date de naissance (jj/mm/aaaa)"
					type="text"
					value={props.formData.naissanceDefunt}
				/>
				<FieldError
					errorMessages={{
						pattern: () => 'La date doit être au format jj/mm/aaaa.',
					}}
					name="naissanceDefunt"
				/>
			</Col>
		</FormGroup>
		<FormGroup className="row align-items-center">
			<Label htmlFor="reclamation-lienDefunt" className="col-md-3 col-lg-4">Lien de filiation avec la personne décédée* :</Label>
			<Col md="7" lg="6">
				<Field
					component={Input}
					id="reclamation-lienDefunt"
					name="lienDefunt"
					placeholder="Lien de filiation"
					required
					type="text"
					value={props.formData.lienDefunt}
				/>
				<FieldError name="lienDefunt" />
			</Col>
		</FormGroup>
		<FormGroup className="row">
			<Col md={{ size: 7, offset: 3 }} lg={{ size: 6, offset: 4 }}>
				<Hint>
					<>
						<p className="m-0">
							Pour un meilleur traitement de votre demande,
							merci d&apos;insérer en pièces jointes les documents suivants :
						</p>
						<ul className="list-unstyled">
							<li>- un acte de décès</li>
							<li>- un relevé de compte du défunt</li>
							<li>- une copie recto/verso de la pièce d&apos;identité du défunt</li>
						</ul>
					</>
				</Hint>
			</Col>
		</FormGroup>
	</>
);

ActeDeces.propTypes = {
	formData: PropTypes.shape({
		nomDefunt: PropTypes.string,
		prenomDefunt: PropTypes.string,
		naissanceDefunt: PropTypes.string,
		lienDefunt: PropTypes.string,
	}).isRequired,
};

export const AssuranceVie = ({ formData, onChange }) => (
	<FormGroup className="row align-items-center">
		<Label htmlFor="reclamation-titulaire" className="col-md-3 col-lg-4">Etes vous titulaire de la demande ou<br />représentant légal* :</Label>
		<Col md="7" lg="6">
			<Field
				component={SwitchComponent}
				//onChange={(e) => !console.log(e) && onChange(e)}
				onChange={onChange}
				id="reclamation-titulaire"
				name="titulaire"
				value={formData.titulaire}
			/>
			<FieldError name="titulaire" />
		</Col>
	</FormGroup>
);

AssuranceVie.propTypes = {
	onChange: PropTypes.func.isRequired,
	formData: PropTypes.shape({
		titulaire: PropTypes.bool.isRequired,
	}).isRequired,
};

export const IncidentCheque = (props) => (
	<>
		<FormGroup className="row align-items-center">
			<Label htmlFor="reclamation-dateIncidentCheque" className="col-md-3 col-lg-4">Date de l&apos;incident* :</Label>
			<Col md="7" lg="6">
				<Field
					className="form-control"
					component={InputMask}
					mask="99/99/9999"
					id="reclamation-dateIncidentCheque"
					name="dateIncidentCheque"
					required
					placeholder="Date de l'incident (jj/mm/aaaa)"
					type="text"
					value={props.formData.dateIncidentCheque}
				/>
				<FieldError
					errorMessages={{
						pattern: () => 'La date doit être au format jj/mm/aaaa.',
					}}
					name="dateIncidentCheque"
				/>
			</Col>
		</FormGroup>
		<FormGroup className="row align-items-center">
			<Label htmlFor="reclamation-numCheque" className="col-md-3 col-lg-4">Numéro du chèque* :</Label>
			<Col md="7" lg="6">
				<Field
					component={Input}
					id="reclamation-numCheque"
					name="numCheque"
					placeholder="Numéro du chèque"
					required
					type="text"
					value={props.formData.numCheque}
				/>
				<FieldError name="numCheque" />
			</Col>
		</FormGroup>
	</>
);

IncidentCheque.propTypes = {
	formData: PropTypes.shape({
		dateIncidentCheque: PropTypes.string,
		numCheque: PropTypes.string,
	}).isRequired,
};

export const LivretEpargne = () => (
	<FormGroup className="row align-items-center">
		<Col md={{ size: 7, offset: 3 }} lg={{ size: 6, offset: 4 }}>
			<Hint hint="Merci de joindre votre dernier avis d'imposition" />
		</Col>
	</FormGroup>
);

export const MotifCustom = (props) => (
	<FormGroup className="row align-items-center">
		<Label className="strong-label col-md-3 col-lg-4" htmlFor="reclamation-motif">Motif2* :</Label>
		<Col md="7" lg="6">
			<Field
				component={Input}
				id="reclamation-motif"
				name="motif"
				placeholder="Motif"
				required
				type="text"
				value={props.formData.motif}
			/>
			<FieldError name="motif" />
		</Col>
	</FormGroup>
);

MotifCustom.propTypes = {
	formData: PropTypes.shape({
		theme: PropTypes.string,
		motif: PropTypes.string,
	}).isRequired,
};

export const Rdv = () => (
	<FormGroup className="row align-items-center pb-5">
		<Col md={{ size: 7, offset: 3 }} lg={{ size: 6, offset: 4 }}>
			<Hint
				hint="Vous avez la possibilité de prendre un rendez vous en ligne facilement et
				simplement en cliquant sur le lien suivant: "
			>
				<a className="d-block" href="https://www.caisse-epargne.fr/cote-d-azur/particuliers/rendez-vous-en-ligne">Prendre rendez-vous</a>
			</Hint>
		</Col>
	</FormGroup>
);

export const VersementFonds = (props) => (
	<FormGroup className="row align-items-center">
		<Label htmlFor="reclamation-numDossier" className="col-md-3 col-lg-4">Numéro de dossier* :</Label>
		<Col md="7" lg="6">
			<Field
				component={Input}
				id="reclamation-numDossier"
				name="numDossier"
				// onChange={props.onChange}
				placeholder="indiqué sur votre formulaire de déblocage."
				required
				type="text"
				value={props.formData.numDossier}
			/>
			<FieldError name="numDossier" />
		</Col>
		<Col md={{ size: 7, offset: 3 }} lg={{ size: 6, offset: 4 }}>
			<Hint
				className="text-left"
				hint="Merci d'insérer en pièce jointe les documents suivants :"
			>
				<ul style={{ marginTop: 15 }}>
					<li>Demande de versement de fonds complétée, signée et datée par vos soins</li>
					<li>Joindre les justificatifs indiqués dans ce présent formulaire.</li>
				</ul>
			</Hint>
		</Col>
	</FormGroup>
);

VersementFonds.propTypes = {
	formData: PropTypes.shape({
		theme: PropTypes.string,
		numDossier: PropTypes.string,
	}).isRequired,
};

export default {
	acteDeces: ActeDeces,
	autres: MotifCustom,
	clauseBeneficiaire: AssuranceVie,
	copieBulletinSituation: AssuranceVie,
	copieOperation: AssuranceVie,
	copieSouscription: AssuranceVie,
	incidentCheque: IncidentCheque,
	//incidentDistributeur: IncidentDistributeur,
	//incidentSecurExpress: IncidentSecurExpress,
	info: MotifCustom,
	livretEpargne: LivretEpargne,
	rdv: Rdv,
	reeditionIfu: AssuranceVie,
	simulationPerp: AssuranceVie,
	versementFonds: VersementFonds,
	versementFondsPro: VersementFonds,
};
