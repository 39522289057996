import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const Spinner = ({ loading, children, className }) => (
	loading ? (
		<span className="d-flex align-items-center">
			{children}
			<FaSpinner size={16} className={classnames('fa-spin ml-2', className)} />
		</span>
	) : children
);

Spinner.propTypes = {
	className: PropTypes.string,
	loading: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
};

Spinner.defaultProps = {
	className: '',
};

export default Spinner;
