import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';
import { FaArrowRight } from 'react-icons/fa';

import Image from '../../Image';

import secret1 from '../../../images/secret1.png';
import secret2 from '../../../images/secret2.png';
import secret3 from '../../../images/secret3.png';
import secret1p from '../../../images/secret1.webp';
import secret2p from '../../../images/secret2.webp';
import secret3p from '../../../images/secret3.webp';

export const FormFaqVisualiserCodeSecret = () => (
	<Row className="faq FormFaqVisualiserCodeSecret">
		<Col xs="12">
			<h2>Comment visualiser votre code secret de carte bancaire dans votre application</h2>
			<p>
				Avec votre application Caisse d&apos;Epargne,
				vous pouvez visualiser votre code de secret de carte bancaire en toute autonomie.
				<br />
				Pour cela, il vous suffit de suivre les étapes ci-dessous :
			</p>
			<ol className="ol-steps">
				<li>
					<Image className="noShadow" src={secret1} webp={secret1p} alt="Etape 1" />
					<p>
						Connectez-vous à<br />
						l&apos;application
					</p>
				</li>
				<li>
					<Image className="noShadow" src={secret2} webp={secret2p} alt="Etape 2" />
					<p>Cliquez sur<br /> « Cartes »</p>
				</li>
				<li>
					<Image className="noShadow" src={secret3} webp={secret3p} alt="Etape 3" />
					<p>Cliquez sur <br />« Voir mon code secret »</p>
				</li>
			</ol>

			<p>
				Attention, pour accéder à ce service, vous devez être enrôlé à Secur&apos;sPass :
				<br />
				<a
					href="https://www.caisse-epargne.fr/cote-d-azur/comptes-cartes/securpass/"
					title="FAQ Caisse d'Epargne"
					className="mb-4 btn btn-outline-dark d-inline-flex align-items-center flex-grow-0 btn-sm"
				>
					<FaArrowRight className="mr-2" />
					en savoir plus
				</a>
			</p>
		</Col>
	</Row>
);
