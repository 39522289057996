import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Footer } from '../components/Footer';
import { HeadSimple } from '../components/HeadSimple';

export const PageNotFound = () => (
	<div>
		<Helmet>
			<title>macaissedepargnecotedazurmerepond.fr – 404</title>
		</Helmet>
		<HeadSimple className="head-404">
			<header className="header">
				<div className="header-title">
					<Container>
						<Row>
							<Col>
								<strong className="xl-title">Page introuvable</strong>
							</Col>
						</Row>
						<Row className="bottom-text">
							<Col>
								<h1 className="md-title">
									Caisse d&apos;Epargne Côte d&apos;Azur
									<strong>Ma Caisse d&apos;Epargne Côte d&apos;Azur me répond</strong>
								</h1>
							</Col>
						</Row>
					</Container>
				</div>
			</header>
		</HeadSimple>
		<Container tag="main" className="mentions-content py-4">
			<Row tag="article">
				<Col>
					<p>
						La page que vous avez demandée n&apos;est pas disponible ou n&apos;existe plus.
					</p>
					<p>
						Veuillez nous excuser pour cet incident.
					</p>
					<Link className="link" to="/">Retour sur la page d&apos;accueil</Link>
				</Col>
			</Row>
		</Container>
		<Footer />
	</div>
);
