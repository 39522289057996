export const SelectStyle = {
	//...styles,
	control: (base, state) => ({
		...base,
		//'&:hover': { borderColor: '#e4e7ea' }, // border style on hover
		//'&:focus': { borderColor: '#707e8a' }, // border style on hover
		//'&:active': { borderColor: '#e4e7ea' }, // border style on hover
		//border: '1px solid #e4e7ea', // default border color
		boxShadow: state.isFocused ? '0 0 0 3px #c1c1c140' : 'none', // no box-shadow
		borderColor: state.isFocused
			? '#c1c1c1'
			: '#ced4da',
		'&:hover': {
			borderColor: state.isFocused
				? '#c1c1c1'
				: '#ced4da',
		},
	//box-shadow: 0 0 0 3px #c1c1c140;;
	}),
	input: (base) => ({
		...base,
		//fontWeight: 'bold',
		'& input': {
			//fontWeight: 'bold',
			//color: '#000',
		},
	}),
	singleValue: (base) => ({
		...base,
		color: '#000',
		fontWeight: 'bold',
		fontSize: '1rem',
		//fontWeight: 400,
		lineHeight: 1.5,
	}),
	option: (base, { isFocused, isSelected }) => {
		const defaultColor = '#5b5b5b';
		let color = defaultColor;
		if (isFocused) {
			color = '#fff';
		}
		if (isSelected) {
			color = defaultColor;
		}
		return {
			...base,
			//backgroundColor: isSelected && '#db002933',
			'&:nth-of-type(2n)': {
				backgroundColor: (isFocused && !isSelected) ? '#999999' : '#fff',
			},
			'&:nth-of-type(2n+1)': {
				backgroundColor: (isFocused && !isSelected) ? '#999999' : '#eee',
			},
			color,
			//fontWeight: 'bold',
			fontWeight: isSelected ? 'bold' : 'normal',
			minHeight: '1.2em',
			padding: '5px 5px',
		};
	},
	placeholder: (base, { isDisabled }) => ({
		...base,
		fontWeight: 'bold',
		color: isDisabled ? '#00000080' : '#000',
	}),
	menu: (provided) => ({
		...provided,
		border: '1px solid #c1c1c1',
	}),
};
