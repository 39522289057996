import {
	Col,
	Container,
	Row,
} from 'reactstrap';
import { FaAngleDown } from 'react-icons/fa';
import PropTypes from 'prop-types';

export const TitleBanner = ({ onClick }) => (
	<Container className="title-banner" onClick={onClick}>
		<Container>
			<Row className="ban border-bottom border-grey">
				<Col className="ban-content py-4">
					<h2>DÉPOSEZ <br /><span>votre demande</span><br /><span className="text-danger"> en ligne !</span></h2>
					<span className="red-circle text-danger">
						<FaAngleDown size={16} />
					</span>
				</Col>
			</Row>
		</Container>
		<Container className="ban-text-container">
			<Row>
				<Col className="ban-text">
					<p>
						A l&apos;aide de ce formulaire vous pouvez nous faire part de votre demande. <br />
						Nous nous engageons à vous répondre dans les meilleurs délais.
					</p>
				</Col>
			</Row>
		</Container>
	</Container>
);

TitleBanner.propTypes = {
	onClick: PropTypes.func.isRequired,
};
