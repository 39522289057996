export default {
	type: 'object',
	properties: {
		civilite: { type: 'string', enum: ['M', 'Mme', 'Mlle'] },
		nom: { type: 'string', minLength: 1 },
		prenom: { type: 'string', minLength: 1 },
		email: { type: 'string', minLength: 5 },
		verifEmail: { type: 'string', minLength: 5, const: { $data: '1/email' } },
		tel: { type: 'string', minLength: 10 },
		dateNaissance: { type: 'string', minLength: 10, pattern: '^([0]?[1-9]|[1|2][0-9]|[3][0|1])/([0]?[1-9]|[1][0-2])/([0-9]{4})$' },
		isClient: { type: 'boolean', enum: [true, false], default: false },
	},
	required: [
		'civilite',
		'nom',
		'prenom',
		'email',
		'verifEmail',
		'tel',
		'dateNaissance',
		'isClient',
	],
};
