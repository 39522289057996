import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';
import Image from '../../Image';

import inter1 from '../../../images/inter1.png';
import inter2 from '../../../images/inter2.png';
import inter3 from '../../../images/inter3.png';
import inter4 from '../../../images/inter4.png';
import inter5 from '../../../images/inter5.png';
import inter1p from '../../../images/inter1.webp';
import inter2p from '../../../images/inter2.webp';
import inter3p from '../../../images/inter3.webp';
import inter4p from '../../../images/inter4.webp';
import inter5p from '../../../images/inter5.webp';

export const FormFaqOptionInternationale = () => (
	<Row className="faq FormFaqOptionInternationale">
		<Col xs="12">
			<h2>Comment souscrire à l&apos;option internationale</h2>
			<p>
				Pour réaliser cette opération en toute autonomie,
				je vous invite à réaliser <strong>les étapes suivantes</strong> :
			</p>
			<ol className="ol-steps">
				<li>
					<Image src={inter1} webp={inter1p} height="600" alt="Etape 1" />
					<p>
						Connectez-vous à votre<br /> application bancaire
					</p>
				</li>
				<li>
					<Image src={inter2} webp={inter2p} height="600" alt="Etape 2" />
					<p>
						Cliquez sur « Plus »<br /> puis sur « Moyen de paiement »
					</p>
				</li>
				<li>
					<Image src={inter3} webp={inter3p} height="600" alt="Etape 3" />
					<p>
						Cliquez sur<br /> « Option internationale »
					</p>
				</li>
				<li>
					<Image src={inter4} webp={inter4p} height="600" alt="Etape 4" />
					<p>
						Cliquez sur<br /> « Comparer les formules »
					</p>
				</li>
				<li>
					<Image src={inter5} webp={inter5p} height="600" alt="Etape 5" />
					<p>
						Sélectionnez l&apos;une des 3<br />formules puis cliquez sur
						<br />
						« Choisir cette formule »
					</p>
				</li>
			</ol>
		</Col>
	</Row>
);
