import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import NavBar from './nav-bar';

export const HeadSimple = ({ children, className }) => (
	<section className={classnames('head-simple', className)}>
		<NavBar />
		{children}
	</section>
);

HeadSimple.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

HeadSimple.defaultProps = {
	className: '',
};
