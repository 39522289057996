const acteDeces = {
	type: 'object',
	properties: {
		prenomDefunt: {
			type: 'string',
		},
		nomDefunt: {
			type: 'string',
		},
		naissanceDefunt: {
			type: 'string',
			pattern: '^([0]?[1-9]|[1|2][0-9]|[3][0|1])/([0]?[1-9]|[1][0-2])/([0-9]{4})$',
		},
		lienDefunt: {
			type: 'string',
		},
	},
	required: [
		'lienDefunt',
		'nomDefunt',
		'prenomDefunt',
		'naissanceDefunt',
	],
};

const incidentCheque = {
	type: 'object',
	properties: {
		dateIncidentCheque: { type: 'string', pattern: '^([0]?[1-9]|[1|2][0-9]|[3][0|1])/([0]?[1-9]|[1][0-2])/([0-9]{4})$' },
		numCheque: { type: 'string' },
	},
	required: [
		'numCheque',
		'dateIncidentCheque',
	],
};

const incidentDistributeur = {
	type: 'object',
	properties: {
		distribOperationType: { type: 'string', enum: ['Retrait', 'Dépôt', 'Autre'] },
		distribDateIncident: { type: 'string', pattern: '^([0]?[1-9]|[1|2][0-9]|[3][0|1])/([0]?[1-9]|[1][0-2])/([0-9]{4})$' },
		distribAgenceIncident: { type: 'string' },
	},
	required: [
		'distribOperationType',
		'distribDateIncident',
		'distribAgenceIncident',
	],
};

const assuranceVie = {
	type: 'object',
	properties: {
		titulaire: { type: 'boolean', enum: [true, false] },
	},
	required: [
		'titulaire',
	],
};

const incidentSecurExpress = {
	type: 'object',
	properties: {
		numPochette: { type: 'number' },
		motifContestation: { type: 'string' },
		dateDepot: { type: 'string', pattern: '^([0]?[1-9]|[1|2][0-9]|[3][0|1])/([0]?[1-9]|[1][0-2])/([0-9]{4})$' },
		agenceDepot: { type: 'string' },
		montantDepot: { type: 'number' },
	},
	required: [
		'numPochette',
		'motifContestation',
		'dateDepot',
		'agenceDepot',
		'montantDepot',
	],
};

const versementFonds = {
	type: 'object',
	properties: {
		numDossier: { type: 'string' },
	},
	required: [
		'numDossier',
	],
};

const versementFondsPro = {
	type: 'object',
	properties: {
		numDossier: { type: 'string' },
	},
	required: [
		'numDossier',
	],
};

const motifCustom = {
	type: 'object',
	properties: {
		motif: { type: 'string', minLength: 1, maxLength: 1000 },
	},
	required: [
		'motif',
	],
};

const rechercheDocsMotifs = {
	type: 'object',
	properties: {
		pricingConditionsAccepted: { type: 'boolean', enum: [true] },
	},
	required: [
		'pricingConditionsAccepted',
	],
};

exports.acteDeces = acteDeces;
exports.assuranceVie = assuranceVie;
exports.incidentCheque = incidentCheque;
exports.incidentDistributeur = incidentDistributeur;
exports.incidentSecurExpress = incidentSecurExpress;
exports.motifCustom = motifCustom;
exports.rechercheDocsMotifs = rechercheDocsMotifs;
exports.versementFonds = versementFonds;
exports.versementFondsPro = versementFondsPro;
