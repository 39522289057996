import React from 'react';
import PropTypes from 'prop-types';
import { FaDesktop, FaMobileAlt, FaBuilding } from 'react-icons/fa';

import './TitleDesktopMobile.scss';

export const TitleDesktopMobile = ({ agence, mobile, desktop }) => (
	<h3 className="TitleDesktopMobile d-flex align-items-center">
		<span className="d-inline-flex justify-content-center mr-2">
			{ mobile && <FaMobileAlt size={16} /> }
			{ desktop && <FaDesktop size={16} /> }
			{ agence && <FaBuilding size={16} /> }
		</span>
		{ mobile && 'Sur votre application Banxo :' }
		{ desktop && 'Sur votre Ordinateur :' }
		{ agence && 'En agence :' }
	</h3>
);

TitleDesktopMobile.propTypes = {
	agence: PropTypes.bool,
	mobile: PropTypes.bool,
	desktop: PropTypes.bool,
};
TitleDesktopMobile.defaultProps = {
	agence: false,
	mobile: false,
	desktop: false,
};
