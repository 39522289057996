import React, { useEffect } from 'react';
import {
	BrowserRouter, Route, Switch, useLocation,
} from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { ScrollToTop } from './components/ScrollToTop';

// pages
import { Accueil } from './pages/Accueil';
import { Charte } from './pages/Charte';
import { CGU } from './pages/CGU';
import { Mentions } from './pages/Mentions';
import { PageNotFound } from './pages/PageNotFound';
import { Cookies } from './pages/Cookies';

import './styles/main.scss';

const Routes = () => {
	const { trackPageView } = useMatomo();
	const location = useLocation();
	useEffect(() => {
		trackPageView({
			documentTitle: location.pathname,
		});
	}, [trackPageView, location]);

	return (
		<ScrollToTop>
			<div>
				<Switch>
					<Route exact path="/" component={Accueil} />
					<Route exact path="/mentions-legales" component={Mentions} />
					<Route exact path="/charte" component={Charte} />
					<Route exact path="/politique-cookies" component={Cookies} />
					<Route exact path="/cgu" component={CGU} />
					<Route path="*" component={PageNotFound} />
				</Switch>
			</div>
		</ScrollToTop>
	);
};

const App = () => (
	<BrowserRouter>
		<Routes />
	</BrowserRouter>
);

export default App;
