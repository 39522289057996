import React from 'react';
import PropTypes from 'prop-types';
import { FaExclamation } from 'react-icons/fa';

const Error = ({ err }) => {
	if (err && err.status === 400) {
		return (
			<div className="error">
				<FaExclamation size={22} style={{ color: '#db0029' }} className="i" />
				<p>Une erreur de validation est survenue merci de vérifier les champs du formulaire.</p>
			</div>
		);
	}
	return (
		<div className="error">
			<FaExclamation size={22} style={{ color: '#db0029' }} className="i" />
			<p>Une erreur est survenue merci de réessayer ultérieurement.</p>
		</div>
	);
};

Error.propTypes = {
	err: PropTypes.shape({
		status: PropTypes.number,
	}).isRequired,
};

export default Error;
