import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';
import { TitleDesktopMobile } from '../../TitleDesktopMobile';

export const FormFaqCode = () => (
	<Row className="faq FormFaqCode">
		<Col xs="12">
			<h2>Réédition du code secret de votre espace sécurisé</h2>
			<p>
				Afin de débloquer au plus vite
				et en toute sécurité votre espace client,
				vous trouverez ci-dessous un tuto vous permettant de réaliser cette opération :
			</p>
			<section className="mb-4">
				<TitleDesktopMobile desktop />
				<ul>
					<li className="d-flex flex-column mb-2">
						<h4>Identifiant oublié :</h4>
						<div className="frame">
							<div className="frame-container">
								<iframe
									width="560"
									height="315"
									src="https://www.youtube-nocookie.com/embed/MANtEa-MpPA"
									title="[Espace Client Web] Comment retrouver votre identifiant de connexion ?"
									// eslint-disable-next-line react/no-unknown-property
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowfullscreen
								/>
							</div>
						</div>
					</li>
					<li className="d-flex flex-column mb-2">
						<h4>Mot de passe oublié :</h4>
						<div className="frame">
							<div className="frame-container">
								<iframe
									width="560"
									height="315"
									src="https://www.youtube-nocookie.com/embed/ujy6DbkHiY4"
									title="[Espace Client Web] Comment retrouver votre mot de passe de connexion ?"
									// eslint-disable-next-line react/no-unknown-property
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowfullscreen
								/>
							</div>
						</div>
					</li>
				</ul>
			</section>
			<section className="mb-4">
				<TitleDesktopMobile mobile />
				<ul>
					<li className="d-flex flex-column mb-2">
						<h4>Identifiant oublié :</h4>
						<div className="frame">
							<div className="frame-container">
								<iframe
									width="560"
									height="315"
									src="https://www.youtube-nocookie.com/embed/hK_Rdsu0AAE"
									title="[App Mobile] Comment retrouver votre identifiant de connexion Caisse d'Epargne ?"
									// eslint-disable-next-line react/no-unknown-property
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowfullscreen
								/>
							</div>
						</div>
					</li>
					<li className="d-flex flex-column mb-2">
						<h4>Mot de passe oublié :</h4>
						<div className="frame">
							<div className="frame-container">
								<iframe
									width="560"
									height="315"
									src="https://www.youtube-nocookie.com/embed/1lsahKR1tGw"
									title="[App Mobile] Comment retrouver votre mot de passe de connexion ?"
									// eslint-disable-next-line react/no-unknown-property
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowfullscreen
								/>
							</div>
						</div>
					</li>
				</ul>
			</section>
			<section className="mb-4">
				<TitleDesktopMobile agence />
				<p>
					En vous présentant à l&apos;accueil
					de l&apos;agence muni(e) de votre pièce d&apos;identité
				</p>
			</section>
		</Col>
	</Row>
);
