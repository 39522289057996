export const MentionsClient = () => (
	<p>
		La Caisse d&apos;Epargne Côte d&apos;Azur,
		recueille en tant que responsable de traitement des données à
		caractère personnel vous concernant.
		Les informations vous expliquant pourquoi et
		comment la Caisse d&apos;Epargne Côte d&apos;Azur utilisera
		vos données, combien de temps elles seront conservées ainsi que les droits dont vous disposez
		s&apos;agissant de ces données figurent dans notre
		notice d&apos;information. Vous pouvez y accéder
		en cliquant ici { ' ' }
		<a href="https://www.caisse-epargne.fr/cote-d-azur/protection-donnees-personnelles">
			https://www.caisse-epargne.fr/cote-d-azur/protection-donnees-personnelles
		</a> et à tout moment sur notre site internet{ ' ' }
		<a href="www.caisse-epargne.fr">
			www.caisse-epargne.fr
		</a> ou sur simple demande auprès de votre agence.
	</p>
);
