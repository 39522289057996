import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';
import Image from '../../Image';

import oppo1 from '../../../images/oppo1.png';
import oppo2 from '../../../images/oppo2.png';
import oppo3 from '../../../images/oppo3.png';
import oppo1p from '../../../images/oppo1.webp';
import oppo2p from '../../../images/oppo2.webp';
import oppo3p from '../../../images/oppo3.webp';

export const FormFaqOppositionPrelevement = () => (
	<Row className="faq FormFaqOpposition">
		<Col xs="12">
			<h2>Opposition à un prélèvement</h2>
			<p>
				Vous trouverez,ci-dessous, les étapes vous permettant de
				réaliser cette opération, je vous invite à vous rendre dans votre
				espace sécurisé disponible 7j/7 et 24h/24, sur notre site Internet
				au <a href="https://www.caisse-epargne.fr">www.caisse-epargne.fr</a>,
				muni de votre identifiant et de votre code secret.
			</p>
			<h3>Pour faire une opposition à un prélèvement :</h3>
			<ol>
				<li>
					<p>Cliquez sur « Moyens de paiement »,</p>
				</li>
				<li className="mb-4">
					<p>Cliquez sur « Prélèvements » puis sur Prélèvements reçus,</p>
					<Image
						src={oppo1}
						webp={oppo1p}
						alt="Etape 1 et 2 - Cliquez sur Moyens de paiment, puis sur prélèvements et enfin prélèvements reçus"
					/>
				</li>
				<li>
					<p>Cliquez sur la flèche qui descend à droite de la ligne du prélèvement souhaité,</p>
				</li>
				<li className="mb-4">
					<p>Cliquez sur Bloquer ce mandat de prélèvement (liste noir),</p>
					<Image
						src={oppo2}
						webp={oppo2p}
						alt="Etape 3 et 4 - Cliquez sur la flèche qui descend à droite de la ligne du prélèvement souhaité, puis sur bloquer ce mandat de prélèvement"
					/>
				</li>
				<li>
					<p>Bloquer le mandat de prélèvement en cliquant sur continuer.</p>
					<Image src={oppo3} webp={oppo3p} alt="Etape 5 - Bloquer le mandat de prélèvement en cliquant sur continuer" />
				</li>
			</ol>
		</Col>
	</Row>
);
