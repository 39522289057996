import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';
import { TitleDesktopMobile } from '../../TitleDesktopMobile';
import Image from '../../Image';

import oppositionDesktop from '../../../images/oppositionDesktop.png';
import oppositionDesktopP from '../../../images/oppositionDesktop.webp';
import opposition1 from '../../../images/opposition1.png';
import opposition2 from '../../../images/opposition2.png';
import opposition3 from '../../../images/opposition3.png';
import opposition1p from '../../../images/opposition1.webp';
import opposition2p from '../../../images/opposition2.webp';
import opposition3p from '../../../images/opposition3.webp';

export const FormFaqOppositionCarte = () => (
	<Row className="faq FormFaqOpposition">
		<Col xs="12">
			<h2>
				Comment faire une opposition à votre
				Carte Bancaire par suite d&apos;une perte ou d&apos;un vol.
			</h2>
			<p>
				Afin d&aspo;effectuer cette démarche au plus vite et en toute sécurité,
				vous trouverez ci-dessous un mode opératoire vous permettant
				de réaliser cette opération en ligne, je vous invite à vous
				rendre dans votre espace sécurisé disponible 7j/7 et 24h/24,
				sur notre site Internet au <a href="https://www.caisse-epargne.fr">www.caisse-epargne.fr</a>, ou application
				bancaire, muni de votre identifiant et de votre code secret.
				<br />
				<br />
				Pour effectuer cette opposition,
				veuillez suivre les étapes suivantes :
			</p>
			<TitleDesktopMobile mobile />
			<ol className="ol-steps">
				<li>
					<Image className="noShadow" src={opposition1} webp={opposition1p} alt="Etape 1" />
					<p>
						Connectez-vous à<br />
						l&apos;application
					</p>
				</li>
				<li>
					<Image className="noShadow" src={opposition2} webp={opposition2p} alt="Etape 2" />
					<p>Cliquez sur<br /> « Cartes »</p>
				</li>
				<li>
					<Image className="noShadow" src={opposition3} webp={opposition3p} alt="Etape 3" />
					<p>Cliquez sur <br />« Faire opposition »</p>
				</li>
			</ol>
			<TitleDesktopMobile desktop />
			<ol>
				<li>
					Sur votre espace client, <br />cliquez sur « Moyens de paiement »,
				</li>
				<li>
					Sélectionnez la carte pour laquelle vous souhaitez faire opposition,
				</li>
				<li>
					Cliquez sur le bouton « Faire opposition ».
				</li>

			</ol>
			<Image className="noShadow" src={oppositionDesktop} webp={oppositionDesktopP} alt="Cliquez sur moyen de paiement, votre carte, et faire opposition" />
		</Col>
	</Row>
);
