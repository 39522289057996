/* eslint-disable no-restricted-globals */
import Helmet from 'react-helmet';
import {
	Col, Container, CustomInput, FormGroup, Label, Row,
} from 'reactstrap';
import React, { useState, useEffect } from 'react';

import { Footer } from '../components/Footer';
import { HeadSimple } from '../components/HeadSimple';
import './Cookies.scss';

window.MatomoConsent = {
	cookiesDisabled: (!navigator || !navigator.cookieEnabled),
	CONSENT_COOKIE_NAME: 'mtm_consent',
	CONSENT_REMOVED_COOKIE_NAME: 'mtm_consent_removed',
	cookieIsSecure: false,
	useSecureCookies: true,
	useCookiesIfNoTracker: 1,
	cookiePath: '',
	cookieDomain: '',
	cookieSameSite: 'Lax',
	init(useSecureCookies, cookiePath, cookieDomain, cookieSameSite) {
		this.useSecureCookies = useSecureCookies; this.cookiePath = cookiePath;
		this.cookieDomain = cookieDomain; this.cookieSameSite = cookieSameSite;
		if (useSecureCookies && location.protocol !== 'https:') {
			// eslint-disable-next-line no-console
			console.log('Error with setting useSecureCookies: You cannot use this option on http.');
		} else {
			this.cookieIsSecure = useSecureCookies;
		}
	},
	hasConsent() {
		const consentCookie = this.getCookie(this.CONSENT_COOKIE_NAME);
		const removedCookie = this.getCookie(this.CONSENT_REMOVED_COOKIE_NAME);
		if (!consentCookie && !removedCookie) {
			return true; // No cookies set, so opted in
		}
		if (removedCookie && consentCookie) {
			this.setCookie(this.CONSENT_COOKIE_NAME, '', -129600000);
			return false;
		}
		return (consentCookie || consentCookie !== 0);
	},
	consentGiven() {
		this.setCookie(this.CONSENT_REMOVED_COOKIE_NAME, '', -129600000);
		this.setCookie(this.CONSENT_COOKIE_NAME, new Date().getTime(), 946080000000);
		// eslint-disable-next-line no-undef
		_paq.push(['forgetUserOptOut']);
	},
	consentRevoked() {
		this.setCookie(this.CONSENT_COOKIE_NAME, '', -129600000);
		this.setCookie(this.CONSENT_REMOVED_COOKIE_NAME, new Date().getTime(), 946080000000);
		// eslint-disable-next-line no-undef
		_paq.push(['optUserOut']);
	},
	getCookie(cookieName) {
		const cookiePattern = new RegExp(`(^|;)[ ]*${cookieName}=([^;]*)`); const
			cookieMatch = cookiePattern.exec(document.cookie);
		return cookieMatch ? window.decodeURIComponent(cookieMatch[2]) : 0;
	},
	setCookie(cookieName, value, msToExpire) {
		const expiryDate = new Date();
		expiryDate.setTime((new Date().getTime()) + msToExpire);
		document.cookie = `${cookieName}=${window.encodeURIComponent(value)
		}${msToExpire ? `;expires=${expiryDate.toGMTString()}` : ''
		};path=${this.cookiePath || '/'
		}${this.cookieDomain ? `;domain=${this.cookieDomain}` : ''
		}${this.cookieIsSecure ? ';secure' : ''
		};SameSite=${this.cookieSameSite}`;
		if ((!msToExpire || msToExpire >= 0) && this.getCookie(cookieName) !== String(value)) {
			// eslint-disable-next-line no-console
			console.log(`There was an error setting cookie \`${cookieName}\`. Please check domain and path.`);
		}
	},
};

const settings = {
	showIntro: true,
	useSecureCookies: true,
	cookiePath: null,
	cookieDomain: null,
	cookieSameSite: 'Lax',
	OptOutErrorNoCookies: 'La fonctionnalité de désactivation du suivi nécessite que les cookies soient autorisés.',
	OptOutErrorNotHttps: "La fonctionnalité de désactivation du suivi pourrait ne pas fonctionner car ce site n'a pas été chargé en HTTPS. Veuillez recharger la page pour vérifier que le statut de ce suivi a bien été changé.",
};

const MatomoOptOut = () => {
	const [consent, setConsent] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		window.MatomoConsent.init(
			settings.useSecureCookies,
			settings.cookiePath,
			settings.cookieDomain,
			settings.cookieSameSite,
		);
		setConsent(window.MatomoConsent.hasConsent());
	}, []);

	const handleConsentChange = (newConsent) => {
		if (newConsent) {
			setConsent(true);
			setErrorMessage(null);
			window.MatomoConsent.consentGiven();
		} else {
			setConsent(false);
			setErrorMessage(null);
			window.MatomoConsent.consentRevoked();
		}
	};

	return (
		<section className="MatomoOptOut mb-5">
			{errorMessage && (
				<p style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</p>
			)}
			{ !errorMessage && (
				<div>
					{settings.showIntro && consent && (
						<p>
							Cela protégera votre vie privée,
							mais empêchera également le propriétaire
							d&apos;apprendre de vos actions et de créer une meilleure
							expérience pour vous et les autres utilisateurs.
						</p>
					)}
					{settings.showIntro && !consent && (
						<p>
							Cookie d&apos;exclusion installé.
							<br />
							Vos visites sur ce site web ne seront PAS enregistrées par notre
							outil d&apos;analyse web.
							<br />
							<br />
							<em>
								Note : si vous nettoyez vos cookies et supprimez
								le cookie d&apos;sexclusion, ou bien si vous changez
								d&apos;ordinateur et/ou de navigateur, il vous
								faudra de nouveau effectuer la procédure d&apos;exclusion.
							</em>
						</p>
					)}
					<FormGroup check className="d-flex align-items-center p-0">
						<CustomInput
							type="checkbox"
							checked={consent}
							onChange={() => handleConsentChange(!consent)}
							id="trackVisits"
						/>
						<Label htmlFor="trackVisits" check>
							<strong>
								<span>
									{ consent ? (
										<>
											Vous n&apos;êtes pas exclu(e). Décochez cette case pour vous exclure.
										</>
									) : (
										<>
											Vous n&apos;êtes actuellement pas suivi(e).
											Cochez cette case pour ne plus être exclu(e).
										</>
									) }
								</span>
							</strong>
						</Label>
					</FormGroup>
				</div>
			)}
		</section>
	);
};

export const Cookies = () => (
	<div className="Cookies">
		<Helmet>
			<title>macaissedepargnecotedazurmerepond.fr – Politique des Cookies</title>
		</Helmet>
		<HeadSimple className="head-mentions">
			<header className="header">
				<div className="header-title">
					<Container>
						<Row>
							<Col>
								<strong className="xl-title">Politique des Cookies</strong>
							</Col>
						</Row>
						<Row className="bottom-text">
							<Col>
								<h1 className="md-title">
									Caisse d&apos;Epargne Côte d&apos;Azur
									<strong>Ma Caisse d&apos;Epargne Côte d&apos;Azur me répond</strong>
								</h1>
							</Col>
						</Row>
					</Container>
				</div>
			</header>
		</HeadSimple>
		<Container tag="main" className="mentions-content py-4">
			<Row tag="article">
				<Col>
					<h2>Qu&apos;est-ce qu&apos;un cookie ?</h2>
					<p>
						Un &quot;cookie&quot; est une suite d&apos;informations,
						généralement de petite taille et identifié par un nom,
						qui peut être transmis à votre navigateur par un site web sur lequel
						vous vous connectez. Votre navigateur web le conservera pendant une certaine durée,
						et le renverra au serveur web chaque fois que vous vous y re-connecterez.
					</p>
					<p>
						Pour en savoir d&apos;avantage sur le sujet,
						vous pouvez consulter le site de la CNIL accessible
						{ ' ' }
						<a href="https://www.cnil.fr/fr/cookies-et-autres-traceurs" target="_blank" rel="noreferrer">
							ici.
						</a>
					</p>
					<p>
						Les cookies sont employés pour diverses raisons.
						Sur macaissedepargnecotedazurmerepond.fr, ils sont exclusivement utilisés
						pour évaluer de manière anonyme l&apos;audience.
					</p>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>Types de cookies déposés par le site</h2>
					<ul>
						<li>
							<p>
								<strong>Cookies Statistiques : </strong> { ' ' }
								afin d&apos;	adapter notre Site <span style={{ wordBreak: 'break-all' }}>www.macaissedepargnecotedazurmerepond.fr</span>  aux demandes de nos visiteurs,
								nous mesurons le nombre de visites,
								le nombre de pages consultées ainsi que de
								l&apos;	activité des visiteurs sur le « Site » et leur fréquence de
								retour. Ces cookies permettent d&apos;	établir des statistiques d&apos;	analyse de
								la fréquentation à partir desquels les contenus de notre Site sont améliorés en
								fonction du succès rencontré par telle ou telle page auprès de nos visiteurs.
								<br />
								<br />
								Listes des cookies utilisés :
								<ul>
									<li>
										&quot;_pk_id_*&quot; : durée de vie de 13 mois
										<br />
										Cookies néccéssaire pour la mesure d&apos;audience
									</li>
									<li>
										&quot;_pk_ses_*&quot; : 30 minutes
										<br />
										Cookies néccéssaire pour la mesure d&apos;audience
									</li>
									<li>
										&quot;mtm_consent&quot; ou &quot;mtm_consent_removed&quot;
										<br />
										sont créés avec une date d&apos;expiration de 30 ans pour se
										souvenir que le consentement a été donné (ou retiré) par l&apos;utilisateur.
									</li>
								</ul>
							</p>
						</li>
					</ul>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>Vos choix en matière de cookie</h2>
					<p>
						En poursuivant votre navigation sur notre Site,
						vous acceptez le dépôt des cookies statistiques sur votre terminal.
						Vous pouvez revenir sur cette décision à tout moment en décochant la case si dessous.
					</p>

					<MatomoOptOut />

				</Col>
			</Row>
		</Container>
		<Footer />
	</div>
);
