import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';
import Image from '../../Image';

import verouillage1 from '../../../images/v1.png';
import verouillage2 from '../../../images/v2.png';
import verouillage3 from '../../../images/v3.png';
import verouillage1P from '../../../images/v1.webp';
import verouillage2P from '../../../images/v2.webp';
import verouillage3P from '../../../images/v3.webp';

export const FormFaqVerouillageTemporaire = () => (
	<Row className="faq FormFaqOpposition">
		<Col xs="12">
			<h2>Comment verrouiller temporairement votre carte Bancaire</h2>
			<p>
				Avec votre application Caisse d&apos;Epargne,
				vous pouvez effectuer ce verrouillage temporaire,
				avant de prendre la décision de faire une opposition définitive,
				nous vous invitons à suivre les étapes suivantes : en toute autonomie.
			</p>
			<ol className="ol-steps">
				<li>
					<Image className="noShadow" src={verouillage1} webp={verouillage1P} alt="Etape 1 - Connectez-vous à l'application" />
					<p>
						Connectez-vous à<br />
						l&apos;application
					</p>
				</li>
				<li>
					<Image className="noShadow" src={verouillage2} webp={verouillage2P} alt="Etape 2 - Cliquez sur le bouton Carte" />
					<p>Cliquez sur<br /> « Cartes »</p>
				</li>
				<li>
					<Image className="noShadow" src={verouillage3} webp={verouillage3P} alt="Etape 3 - Cliquez sur Verouillage temporaire" />
					<p>Cliquez sur <br />«Verouillage temporaire »</p>
				</li>
			</ol>
		</Col>
	</Row>
);
