import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { demandeTypes } from '../schemas/data/data';

import { SelectStyle } from './SelectStyle';

const SelectMotif = ({
	disabled,
	options,
	onChange,
	value,
}) => {
	const optionsFormatted = useMemo(() => options
		.sort((a, b) => demandeTypes[a].label.localeCompare(demandeTypes[b].label))
		.map((dt) => ({ label: demandeTypes[dt].label, value: dt })),
	[options]);

	return (
		<Select
			isDisabled={disabled}
			isSearchable
			isClearable
			name="theme"
			placeholder="Recherchez ou sélectionnez un motif"
			options={optionsFormatted}
			onChange={onChange}
			noOptionsMessage={() => 'Aucune motif ne correspond à votre recherche'}
			styles={SelectStyle}
			value={optionsFormatted.filter((obj) => obj.value === value)}
		/>
	);
};

SelectMotif.propTypes = {
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.string),
	value: PropTypes.string,
};
SelectMotif.defaultProps = {
	disabled: false,
	options: [],
	value: '',
};

export default SelectMotif;
