import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { Footer } from '../components/Footer';
import { HeadSimple } from '../components/HeadSimple';

export const CGU = () => (
	<div>
		<Helmet>
			<title>macaissedepargnecotedazurmerepond.fr – Conditions générales d&apos;utilisation</title>
		</Helmet>
		<HeadSimple className="head-charte">
			<header className="header">
				<div className="header-title">
					<Container>
						<Row>
							<Col>
								<strong className="xl-title text-uppercase">Conditions générales D&apos;UTILISATION</strong>
							</Col>
						</Row>
						<Row className="bottom-text">
							<Col>
								<h1 className="md-title">
									Caisse d&apos;Epargne Côte d&apos;Azur
									<strong>Ma Caisse d&apos;Epargne Côte d&apos;Azur me répond</strong>
								</h1>
							</Col>
						</Row>
					</Container>
				</div>
			</header>
		</HeadSimple>
		<Container tag="main" className="mentions-content py-4">
			<Row tag="article">
				<Col>
					<h2>Préambule</h2>
					<p>
						Les présentes «conditions générales d&apos;utilisation »
						ont pour objet l&apos;encadrement
						juridique des modalités de mise à disposition des services du site
						www.macaissedepargnecotedazurmerepond.fr et leur utilisation par « l&apos;Utilisateur ».
						Les conditions générales d&apos;utilisation doivent être lues et acceptées par
						tout Utilisateur souhaitant accéder au site. Elles constituent le contrat
						entre le site et l&apos;Utilisateur. L&apos;accès
						au site par l&apos;Utilisateur signifie
						son acceptation des présentes conditions générales d&apos;utilisation.
						Ces Conditions Générales peuvent être modifiées à tout moment par
						la Caisse d&apos;Epargne Côte d&apos;Azur
						et entrent en vigueur à compter de leur mise en ligne sur le Site.
					</p>
					<p>
						Les Conditions Générales applicables sont celles en vigueur au moment
						de l&apos;accès au site www.macaissedepargnecotedazurmerepond.fr.
					</p>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>Article 1. Informations générales</h2>
					<p>
						Les Conditions Générales s&apos;appliquent à tout accès et toute consultation du
						Site par un Utilisateur ainsi qu&apos;à toute utilisation du Site par un Utilisateur.
						L&apos;acceptation des Conditions Générales par tout Utilisateur est un préalable
						indispensable et obligatoire. Cette acceptation résulte pour l&apos;Utilisateur,
						de l&apos;accès au Site et de son clic d&apos;acceptation sur la case figurant en regard
						de la mention « J&apos;accepte les conditions générales d&apos;utilisation ».
					</p>
					<p>
						L&apos;Utilisateur reconnaît expressément qu&apos;il a pris connaissance et accepté
						les présentes Conditions générales et informations légales et s&apos;engage à
						les respecter.
					</p>
					<p>
						Pour des raisons de maintenance mais également en cas de force majeure,
						difficultés informatiques, difficultés liées aux réseaux de télécommunications
						ou autres difficultés techniques, l&apos;Editeur pourra
						interrompre l&apos;accès au site et
						ne saurait être tenu responsable de l&apos;impossibilité d&apos;accéder au présent site.
						L&apos;accès à tout ou partie des produits et services décrits sur ce site peut faire
						l&apos;objet de restrictions à l&apos;égard de certaines personnes ou de certains pays.
						Aucun des produits ou services présentés ici ne sera fourni à une personne
						si la loi de son pays d&apos;origine, ou de tout autre pays qui la concernerait,
						l&apos;interdit.
					</p>
					<p>
						La Caisse d&apos;Epargne Côte d&apos;Azur se réserve le droit
						d&apos;apporter des modifications
						aux Conditions Générales. Toute modification entre en vigueur à compter
						de sa date de mise en ligne, ou le cas échéant à toute autre date qui serait
						indiquée dans toute condition particulière applicable au service.
						Tout Utilisateur est réputé avoir accepté la nouvelle version des Conditions Générales,
						du Site et/ou des services du simple fait de l&apos;accès au Site
						(pour tout Utilisateur et/ou de la poursuite de l&apos;utilisation des services
						(pour tout Utilisateur).
					</p>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>Article 2. Objet du Site</h2>
					<p>
						La Caisse d&apos;Epargne Côte d&apos;Azur met à disposition de ses Utilisateurs
						un Site permettant un espace d&apos;expression permettant d&apos;effectuer une demande.
						Une charte d&apos;utilisation est à disposition des Utilisateurs sur le site et
						par ce lien : <Link to="/charte">accédez à la charte d&apos;utilisation</Link>
					</p>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>Article 3. Descriptif du Site</h2>
					<p>
						L&apos;accès à ce Site est gratuit. Les frais
						d&apos;accès et d&apos;utilisation du réseau
						de communication électronique sont à la charge de l&apos;Utilisateur,
						selon les modalités fixées par ses fournisseurs d&apos;accès et opérateurs de
						communication électronique. L&apos;Utilisateur est entièrement responsable de la
						fourniture et de l&apos;utilisation des équipements nécessaires pour la connexion
						au Site.
					</p>
					<p>
						L&apos;Utilisateur reconnaît de disposer de la compétence et des
						moyens nécessaires pour accéder et utiliser ce Site.
					</p>
					<p>
						Il permet aux Utilisateurs d&apos;effectuer une demande auprès de la
						Caisse d&apos;Epargne Côte d&apos;Azur,
						en choisissant un thème et un motif sur la liste déroulante présente sur le site.
						Informations mises à disposition sur le site ou sur les sites tiers référencés
					</p>
					<p>
						L&apos;Editeur s&apos;efforce d&apos;assurer l&apos;exactitude et la mise à jour des
						informations à caractère général, notamment
						financières, diffusées sur ce site, dont elle
						se réserve le droit de modifier et corriger le contenu à tout moment et
						sans préavis. En conséquence, l&apos;Editeur ne garantit en aucune façon la
						complétude, la précision, l&apos;exactitude, l&apos;exhaustivité
						ou l&apos;adéquation, des
						informations mises à disposition sur ce site, y compris l&apos;ensemble des liens
						hypertextes ou toute autre liaison informatique utilisée, directement ou indirectement
						à partir de ce site.
					</p>
					<p>
						Les aspects financiers et performances des produits indiquées sur ce site
						ne sont que le reflet d&apos;une situation constatée à un instant donné et ne sauraient,
						en aucun cas, préjuger de l&apos;évolution des taux et des cours des produits concernés.
						A cet égard, l&apos;Editeur décline toute
						responsabilité dans l&apos;utilisation qui pourrait
						être faite de ces informations et des conséquences qui pourraient en découler,
						notamment au niveau des décisions qui pourraient être prises ou des actions qui
						pourraient être entreprises à partir desdites informations. Vous demeurez seul et
						unique responsable de l&apos;usage des informations et des résultats obtenus à
						partir de ces informations.
					</p>
					<p>
						L&apos;Editeur attire tout particulièrement
						votre attention sur le fait que l&apos;utilisation
						et l&apos;interprétation des informations relatives aux produits financiers peut parfois
						nécessiter des connaissances spécifiques et approfondies. Il appartient à toute personne
						intéressée de vérifier les informations mises à disposition et d&apos;en faire un
						usage approprié.
					</p>
					<p>L&apos;Editeur décline en conséquence toute responsabilité :</p>
					<ul>
						<li>
							en cas d&apos;imprécision, inexactitude, erreur ou omission
							portant sur des informations disponibles sur le site ou sur les
							sites de tiers référencés à partir des liens hypertextes ;
						</li>
						<li>
							d&apos;une absence de disponibilité des informations ;
						</li>
						<li>
							pour tous dommages, directs et/ou indirects, quelles qu&apos;en soient
							les causes, origines,
							nature ou conséquences, provoqués à raison de l&apos;accès de quiconque au
							site ou de l&apos;impossibilité
							d&apos;y accéder ;
						</li>
						<li>
							de l&apos;utilisation du site et/ou du crédit accordé à une quelconque
							information provenant
							directement ou indirectement du site ;
						</li>
						<li>
							de décisions prises sur la base d&apos;une information contenue sur le site ou sur
							le site de tiers référencés à partir des liens hypertextes,
							et de l&apos;utilisation qui
							pourrait en être faite par des tiers.
						</li>
					</ul>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>Article 4. Obligations de l&apos;Utilisateur</h2>
					<p>
						L&apos;Utilisateur s&apos;engage à
						ne pas reproduire, diffuser ou faire diffuser par tout moyen
						direct ou indirect le contenu total ou partiel des Programmes immobiliers figurant
						sur le Site, les offres ayant été négociées spécifiquement dans le cadre de la relation
						que les Promoteurs entretiennent avec la Caisse d&apos;Epargne Côte d&apos;Azur.
					</p>
					<p>
						Il est strictement interdit de mettre en place un lien hypertexte en
						direction du site sans l&apos;autorisation écrite et préalable de l&apos;Editeur.
						Toute demande tendant à cette fin
						doit être cecaz-b-expressionclient-src@cecaz.caisse-epargne.fr.
					</p>
				</Col>
			</Row>

			<Row tag="article">
				<Col>
					<h2>Article 5. Propriété Intellectuelle</h2>
					<p>
						La Caisse d&apos;Epargne Côte d&apos;Azur exploite le Service.<br />
						Le site est la propriété de la Caisse d&apos;Epargne
						Côte d&apos;Azur, à l&apos;exception des marques,
						logos ou contenus appartenant à d&apos;autres sociétés partenaires ou auteurs.<br />
						L&apos;ensemble des contenus (textes, sons, marques, logos, photographies, etc…),
						figurant sur les pages de ce site sont la propriété exclusive de l&apos;éditeur.<br />
						Toute représentation ou reproduction en partie ou en totalité est interdite sans
						l&apos;accord exprès de la Caisse d&apos;Epargne Côte d&apos;Azur et constituerait
						une contrefaçon sanctionnée
						par les articles L. 335-2 et suivants du Code de la Propriété
						Intellectuelle susceptible d&apos;engager
						la responsabilité civile et pénale du Contrefacteur.
					</p>
				</Col>
			</Row>

			<Row tag="article">
				<Col>
					<h2>Article 6. Sous-Traitance</h2>
					<p>
						La Caisse d&apos;Epargne Côte d&apos;Azur pourra sous-traiter tout ou partie de se
						obligations à des tiers, à condition cependant que la Caisse d&apos;Epargne
						Côte d&apos;Azur reste garante de la bonne exécution des présentes.
					</p>
				</Col>
			</Row>
			{/* <Row tag="article">
				<Col>
					<h2>Article 7. Cookies</h2>
					<p>
						L&apos;Utilisateur est informé que ce site a recours à des témoins
						de connexion (usuellement dénommé « cookie ») qui peuvent s&apos;installer
						automatiquement et être conservés temporairement en mémoire ou sur son
						disque dur. Les cookies utilisés permettent notamment de faciliter
						la navigation sur le Site. Un cookie est un mini-fichier contenant
						quelques lignes de caractères alphanumériques placé sur votre disque
						dur par le serveur du site ou de l&apos;application mobile que vous visitez,
						ou par un serveur tiers (par exemple un service de web analytique),
						cet élément peut servir à enregistrer des informations relatives à
						la navigation de l&apos;Utilisateur et à rationaliser les procédures
						d&apos;enregistrement ou de mesure d&apos;audience
					</p>
					<p>
						Il contient plusieurs données : le nom du serveur qui l&apos;a déposé
						; un identifiant sous forme de numéro unique, éventuellement une date d&apos;expiration.
					</p>
					<p>
						Ainsi, lorsque vous visitez le site pour chercher, lire ou télécharger
						de l&apos;information, certains renseignements vous concernant peuvent
						être recueillis, tels le nom du domaine et de l&apos;ordinateur hôte à
						partir duquel vous naviguez sur internet, l&apos;adresse du protocole internet
						(IP) de l&apos;ordinateur utilisé, la date et l&apos;heure de la navigation et les
						URL à partir desquelles vous êtes passé. Ces renseignements sont utilisés
						pour analyser et mesurer la fréquentation du site et pour aider à la
						rendre plus utile. Ils sont détruits après un certain temps.
					</p>
					<p>
						L&apos;Utilisateur qui le désire peut refuser les cookies en modifiant
						les paramètres de son navigateur.
					</p>
					<p>
						Les cookies mis en œuvre ne permettent pas un traçage de l&apos;internaute.
					</p>
					<p>
						Cette manipulation entraînera cependant la suppression de tous les
						cookies utilisés par le navigateur, y compris ceux employés par d&apos;autres
						sites internet, ce qui peut conduire à la perte de certaines informations ou réglages.
					</p>
					<p>
						La suppression des cookies peut être de nature à altérer, voire à
						rendre impossible la navigation sur le Site. Pour plus d&apos;informations,
						notamment concernant la suppression et la gestion des cookies,
						l&apos;Utilisateur est invité à consulter la page internet suivante :
						<Link className="link" to="/politique-cookies">Politique des Cookies</Link>
					</p>
				</Col>
			</Row> */}

			<Row tag="article">
				<Col>
					<h2>Article 8. Protection des données à caractère personnel</h2>
					<p>
						Les données à caractère personnel que vous nous communiquez par
						le biais des formulaires disponibles sur ce site (par exemple une
						demande de contact ou de renseignement, une simulation,.) sont
						traitées par la banque, responsable de traitement, à des fins de
						gestion interne et pour répondre à votre demande.
					</p>
					<p>
						D&apos;une manière générales, les informations vous expliquant de quelle
						manière vos données sont obtenues, pourquoi elles sont traitées, avec
						qui elles sont susceptibles d&apos;être partagées, les mesures mises en oeuvre
						pour assurer leur confidentialité et leur sécurité figurent dans
						la Notice d&apos;information sur les traitements des données à caractère
						personnel de Caisse d&apos;Epargne. Elle rappelle également les droits dont
						vous disposez et comment les exercer.
					</p>
					<p>
						Pour en savoir plus sur notre notice d&apos;information sur le traitement
						des données personnelles { ' ' }
						<a href="https://www.caisse-epargne.fr/cote-d-azur/protection-donnees-personnelles">https://www.caisse-epargne.fr/cote-d-azur/protection-donnees-personnelles</a>
					</p>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>Article 9.. Convention sur la preuve</h2>
					<p>
						Les Parties entendent fixer, dans le cadre du Service, les règles relatives
						aux preuves recevables entre eux en cas de litige et à leur force probante.
						Les stipulations qui suivent constituent ainsi la convention de preuve passée entre
						les parties, lesquelles s&apos;engagent à respecter le présent article.
					</p>
					<p>
						Le Service procède aux enregistrements informatiques des connexions et des opérations
						réalisées au cours de son utilisation. Sont expressément concernés les cases cochées,
						les dépôts de pièces, les clics, les courriers électroniques adressés et
						reçus par la Banque,
						les accusés de réception émis et reçus par la Banque. Les parties reconnaissent que ces
						enregistrements de connexions,
						d&apos;opérations et d&apos;informations, courriers électroniques,
						accusés de réception, sont admissibles devant les tribunaux et font preuve des
						données et
						des éléments qu&apos;ils contiennent et ce, quel que soit le support utilisé pour
						ces enregistrements.<br />
						La preuve contraire peut être rapportée.
					</p>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>Article 10. Intégralité des relations</h2>
					<p>
						Les Parties reconnaissent que les dispositions des Conditions Générales
						constituent l&apos;intégralité des accords intervenus entre elles en ce qui concerne
						la réalisation de l&apos;objet des présentes et annulent et remplacent tous accords ou
						propositions antérieures ayant le même objet, quelle qu&apos;en soit la forme.
					</p>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>Article 11. Cessibilité des Conditions Générales</h2>
					<p>
						La Caisse d&apos;Epargne Côte d&apos;Azur se réserve la faculté de céder, transférer ou
						apporter à un tiers, sous quelque forme que ce soit, les droits et obligations
						nés des présentes CGU.
					</p>
				</Col>
			</Row>
			<Row tag="article">
				<Col>
					<h2>
						Article 12. Loi applicable et juridiction compétente
					</h2>
					<p>
						Les parties conviennent que la loi applicable est la loi Française,
						que les litiges sont de la compétence exclusive des tribunaux Français.
					</p>
					<p>
						A défaut de règlement amiable, en cas de litige relatif à l&apos;interprétation,
						la formation ou l&apos;exécution des
						Conditions générales d&apos;utilisation et faute d&apos;être
						parvenus à un accord amiable ou à une transaction, les Parties donnent compétence
						expresse et exclusive aux tribunaux
						compétents du ressort de la Cour d&apos;appel d&apos;Aix
						en Provence, nonobstant pluralité
						de défendeurs ou d&apos;action en référé
						ou d&apos;appel en garantie ou de mesure conservatoire.
					</p>
				</Col>
			</Row>
		</Container>
		<Footer />
	</div>
);
