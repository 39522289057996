const EXPR_CLIENT_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/demande`;

const formatDemandDistributeur = (demande) => ({
	distribAgenceIncident: demande.distribAgenceIncident,
	distribOperationType: demande.distribOperationType,
	distribDateIncident: demande.distribDateIncident.replace(/\//g, '-'),
});

const formatDemandRechercheDocs = (demande) => ({
	pricingConditionsAccepted: demande.pricingConditionsAccepted,
});

const formatDemandSecurExpress = (demande) => ({
	agenceDepot: demande.agenceDepot,
	dateDepot: demande.dateDepot.replace(/\//g, '-'),
	montantDepot: demande.montantDepot,
	motifContestation: demande.motifContestation,
	numPochette: demande.numPochette,
});

const formatDemandCheque = (demande) => ({
	dateIncidentCheque: demande.dateIncidentCheque.replace(/\//g, '-'),
	numCheque: demande.numCheque,
});

const formatDemandVersementFonds = (demande) => ({
	numDossier: demande.numDossier,
});

const formatCustomMotif = (demande) => ({
	motif: demande.motif,
});

const formatDemandSuccession = (demande) => ({
	lienDefunt: demande.lienDefunt,
	naissanceDefunt: demande.naissanceDefunt.replace(/\//g, '-'),
	nomDefunt: demande.nomDefunt,
	prenomDefunt: demande.prenomDefunt,
});

const formatDemandAV = (demande) => ({
	titulaire: demande.titulaire,
});

const formatDemand = (demande) => {
	let demand = {};
	if (demande.theme === 'assuranceVie') { demand = formatDemandAV(demande); }
	if (demande.theme === 'autres' || demande.theme === 'info') { demand = formatCustomMotif(demande); }
	if (demande.demandeType === 'incidentDistributeur') { demand = formatDemandDistributeur(demande); }
	if (demande.demandeType === 'incidentSecurExpress') { demand = formatDemandSecurExpress(demande); }
	if (demande.demandeType === 'incidentCheque') { demand = formatDemandCheque(demande); }
	if (demande.demandeType === 'versementFonds') { demand = formatDemandVersementFonds(demande); }
	if (demande.demandeType === 'versementFondsPro') { demand = formatDemandVersementFonds(demande); }
	if (demande.theme === 'rechercheDocs') { demand = formatDemandRechercheDocs(demande); }
	if (demande.theme === 'successions') { demand = formatDemandSuccession(demande); }

	demand = {
		...demand,
		clientType: demande.vousEtes,
		civilite: demande.civilite,
		dateNaissance: demande.dateNaissance.replace(/\//g, '-'),
		demande: demande.demande,
		demandeType: demande.demandeType,
		email: demande.email.trim(),
		isClient: demande.isClient,
		nom: demande.nom,
		prenom: demande.prenom,
		tel: demande.tel,
	};
	return demand;
};

// eslint-disable-next-line import/prefer-default-export
export function create(demand) {
	const formattedDemande = formatDemand(demand);
	const data = new FormData();
	demand.files.map((file) => data.append('files', file));
	data.append('data', JSON.stringify(formattedDemande));
	return fetch(
		`${EXPR_CLIENT_API_URL}/create`,
		{
			body: data,
			method: 'POST',
		},
	);
}
