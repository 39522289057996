import Rollbar from 'rollbar';

const { REACT_APP_ROLLBAR_ACCESS_TOKEN, REACT_APP_VERSION, REACT_APP_BUILD_ID } = process.env;
// eslint-disable-next-line camelcase
const codeVersion = `${REACT_APP_VERSION}/${REACT_APP_BUILD_ID}`;

const rollbar = new Rollbar({
	accessToken: REACT_APP_ROLLBAR_ACCESS_TOKEN,
	captureUncaught: true,
	captureUnhandledRejections: true,
	enabled: (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging'),
	payload: {
		environment: process.env.REACT_APP_ENV,
		client: {
			javascript: {
				source_map_enabled: true,
				code_version: codeVersion,
				guess_uncaught_frames: true,
			},
		},
	},
});

global.rollbar = rollbar;

export default rollbar;
