import React from 'react';
import {
	Col,
	Row,
} from 'reactstrap';
import Image from '../../Image';

import rib1 from '../../../images/rib1.png';
import rib2 from '../../../images/rib2.png';
import rib3 from '../../../images/rib3.png';
import rib4 from '../../../images/rib4.png';
import rib5 from '../../../images/rib5.png';
import rib1P from '../../../images/rib1.webp';
import rib2P from '../../../images/rib2.webp';
import rib3P from '../../../images/rib3.webp';
import rib4P from '../../../images/rib4.webp';
import rib5P from '../../../images/rib5.webp';

export const FormFaqRIBSmartphone = () => (
	<Row className="faq FormFaqRIBSmartphone">
		<Col xs="12">
			<h2>Comment ajouter un RIB depuis son smartphone</h2>
			<p>
				Vous pouvez réaliser cette opération via votre service
				de banque à distance accessible sur votre application mobile
				ou sur le site internet de la Caisse d&apos;Epargne :
				{' '}
				<a href="https://www.caisse-epargne.fr">www.caisse-epargne.fr</a>.
			</p>
			<ol className="ol-steps">
				<li>
					<Image src={rib1} src2={rib1P} alt="rib1" height="600" />
					<p>
						Connectez-vous à votre<br /> application bancaire
					</p>
				</li>
				<li>
					<Image src={rib2} src2={rib2P} alt="rib2" height="600" />
					<p>Cliquez sur <br />« Virements »</p>
				</li>
				<li>
					<Image src={rib3} src2={rib3P} alt="rib3" height="600" />
					<p>Cliquez sur <br />« Mes bénéficiaires »</p>
				</li>
				<li>
					<Image src={rib4} src2={rib4P} alt="rib4" height="600" />
					<p>Cliquez sur <br />« Ajouter un bénéficiaire »</p>
				</li>
				<li>
					<Image src={rib5} src2={rib5P} alt="rib5" height="600" />
					<p>Saisissez une des options<br /> pour renseigner l&apos;IBAN</p>
				</li>
			</ol>
			<p className="mb-0">
				Un Tuto vidéo est à votre disposition ci-dessous :
			</p>
			<div className="frame">
				<div className="frame-container">
					<iframe
						width="560"
						height="315"
						src="https://www.youtube-nocookie.com/embed/FkLFSti5h4A"
						title="Comment ajouter un bénéficiaire ?"
						// eslint-disable-next-line react/no-unknown-property
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
					/>
				</div>
			</div>
			<p className="mt-4">
				Vous pouvez également vous présenter à
				l&apos;accueil de l&apos;agence muni(e) de votre pièce
				d&apos;identité ainsi que le relevé d&apos;identité
				bancaire du bénéficiaire.
			</p>
		</Col>
	</Row>
);
