import PropTypes from 'prop-types';
import React from 'react';
import Switch from 'react-switch';

const SwitchComponent = (props) => {
	const {
		onChange,
		value,
		name,
		...rest
	} = props;

	return (
		<div className="switch-container">
			<span>Non</span>
			<Switch
				checked={value}
				className="mx-1"
				offColor="#e0e0e0"
				onColor="#f2baba"
				onHandleColor="#db0029"
				offHandleColor="#5e5f60"
				height={20}
				handleDiameter={28}
				uncheckedIcon={false}
				checkedIcon={false}
				onChange={(val) => {
					onChange({
						[name]: val,
					});
				}}
				{...rest}
			/>
			<span>Oui</span>
		</div>
	);
};

SwitchComponent.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
};

export default SwitchComponent;
