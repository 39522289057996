/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
	Carousel,
	CarouselItem,
	Container,
	Row,
	Col,
} from 'reactstrap';
import Image from './Image';

import villageImg from '../images/slider-village-filter.jpg';
import villageImgP from '../images/slider-village-filter.webp';
import vignesImg from '../images/slider-vignes-filter.jpg';
import vignesImgP from '../images/slider-vignes-filter.webp';
import niceImg from '../images/slider-nice-filter.jpg';
import niceImgP from '../images/slider-nice-filter.webp';
import talkIcon from '../images/img_talk.svg';

const items = [
	{
		key: 1,
		src: villageImg,
		webp: villageImgP,
		altText: 'Village',
	},
	{
		key: 2,
		src: vignesImg,
		webp: vignesImgP,
		altText: 'Vignes',
	},
	{
		key: 3,
		src: niceImg,
		webp: niceImgP,
		altText: 'Nice',
	},
];

class Header extends React.Component {
	constructor() {
		super();

		this.state = {
			activeIndex: 0,
		};

		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}

	next() {
		const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
		this.setState({ activeIndex: nextIndex });
	}

	previous() {
		const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
		this.setState({ activeIndex: nextIndex });
	}

	render() {
		const { activeIndex } = this.state;

		return (
			<header className="header">
				<Carousel
					activeIndex={activeIndex}
					interval={4000}
					next={this.next}
					pause={false/* fix: unpredictable carousel stop*/}
					previous={this.previous}
					ride="carousel"
					slide
				>
					{
						items.map((item) => (
							<CarouselItem key={item.key}>
								<div className="carousel-img-container">
									<Image
										src={item.src}
										webp={item.webp}
										alt={item.altText}
									/>
								</div>
							</CarouselItem>
						))
					}
				</Carousel>
				<div className="header-title">
					<Container>
						<Row>
							<Col>
								<strong className="xl-title">BIENVENUE <img src={talkIcon} alt="talk" className="talkImg" /></strong>
							</Col>
						</Row>
						<Row className="bottom-text">
							<Col>
								<h1 className="md-title">
									sur votre espace d&apos;expression
									<strong>AVEC LA CAISSE D&apos;EPARGNE <br /> CÔTE D&apos;AZUR</strong>
								</h1>
							</Col>
						</Row>
					</Container>
				</div>
			</header>
		);
	}
}

export default Header;
