/* eslint-disable react/no-unescaped-entities*/
import { Link } from 'react-router-dom';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import Socials from './socials';

export const Footer = () => (
	<Container tag="footer" className="footer mb-md-5">
		<Row noGutters>
			<Col className="footer-content py-4 border-top border-grey">
				<p className="footer-copyright my-4 my-lg-0 mb-2 mb-md-2 mr-lg-3 text-center" onClick={() => global.rollbar.info('hello')}>
					© CAISSE D'EPARGNE CÔTE D'AZUR {new Date().getFullYear()}
				</p>
				<div className="footer-legal-wrap mr-lg-4">
					<p className="footer-legal my-0">
						<Link className="footer-link text-uppercase" to="/cgu">Conditions générales d&apos;UTILISATION</Link>
					</p>
					<p className="footer-legal my-0">
						<Link className="footer-link text-uppercase" to="/mentions-legales">MENTIONS LEGALES</Link>
					</p>
					<p className="footer-legal my-0">
						<Link className="footer-link text-uppercase" to="/politique-cookies">
							POLITIQUE DES COOKIES
						</Link>
					</p>
				</div>
				<p className="footer-charte my-0">
					<Link className="footer-link" to="/charte">CHARTE D&apos;UTILISATION</Link>
				</p>
				<p className="footer-cecaz pr-md-3 my-0 mr-lg-3">
					NOTRE SITE <a className="footer-link text-uppercase" href="https://www.caisse-epargne.fr/cote-d-azur/">CAISSE D'EPARGNE CÔTE D'AZUR</a>
				</p>
				<Socials />
			</Col>
		</Row>
	</Container>
);
